import React from "react";
import { Redirect, useParams } from "react-router-dom";
// import classnames from "classnames";
import log from "loglevel";
import { useLobby } from "../context/LobbyProvider";
// import { useShow, SHOW_STATES } from "../context/ShowProvider";
import NotFound from "./404";
import NotOpen from "./NotOpen";
import GreenRoom from "./puzzles/GreenRoom";
import SoundBooth from "./puzzles/SoundBooth";
import JanitorsCart from "./puzzles/JanitorsCart";

export default function PuzzleRoom() {
  const { id } = useParams();
  const { allRooms, showState } = useLobby();
  // const { state } = useShow();

  const room = allRooms.find((r) => r.roomId === id);

  if (showState === "Closed" || showState === "Ended") {
    log.info("Puzzle", "Show closed, redirecting to entrance.");
    return <Redirect to="/" />;
  }

  if (showState === "Preshow") {
    log.info("Puzzle", "Show not open yet.");
    return <NotOpen />;
  }

   if (showState === "Finale" || showState === "Afterparty") {
    log.info("Puzzle", "Show over, redirecting to main navigation.");
    return <Redirect to="/home" />;
  }
  
  if (!room) {
    return <NotFound />;
  }

  switch (room.metadata.key) {
    case "GREEN":
      return <GreenRoom />;
    case "SOUND":
      return <SoundBooth />;
    case "CLOSET":
      return <JanitorsCart />;
    default:
      return <NotFound />;
  }
};
