import React, { createContext, useState, useContext, useCallback } from "react";
import log from "loglevel";
import { useColyseusClient } from "./ColyseusClientProvider";
import { useUser } from "./UserProvider";

const RoomContext = createContext(null);

function ColyseusRoomProvider (props) {
  const client = useColyseusClient();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const [room, setRoom] = useState(null);
  // const [roomState, setRoomState] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const joinRoom = useCallback(
    async function(roomId) {
      if (loading || (room && room.id === roomId)) return;
      if (room && room.id !== roomId) {
        log.info("ColyseusRoomProvider:", "leaveRoom >", room.id);
        room.removeAllListeners();
        room.leave();
        setRoom(null);
        setErrorMessage("");
      }
      setLoading(true);
      setErrorMessage("");
      try {
        const newRoom = await client.joinById(roomId, { accessToken: user.token });
        if (newRoom) {
          log.debug("ColyseusRoomProvider:", "joinRoom >", roomId, "success");
          newRoom.onLeave((code) => {
            log.info("ColyseusRoomProvider:", "onLeave", code);
            newRoom.removeAllListeners();
            setRoom(null);
            setErrorMessage("");
          });
          newRoom.onError((code) => {
            log.warn("ColyseusRoomProvider:", "onError", code);
            newRoom.removeAllListeners();
            setRoom(null);
            setErrorMessage("An error occurred.");
          });

          newRoom.onStateChange.once((updatedRoomState) => {
            log.debug("ColyseusRoomProvider:", "onStateChange", roomId);
            // setRoomState(updatedRoomState);
          });
          setRoom(newRoom);
        }
      } catch (err) {
        log.error("ColyseusRoomProvider:", "joinRoom >", err);
        if (err.code === 4212) {
          setErrorMessage("That room is no longer open.");
        } else if (err.code === 401 || err.code === 400) {
          setErrorMessage("You do not have permission to access that room.");
        } else {
          setErrorMessage("An error occurred.");
        }
      } finally {
        setLoading(false);
      }
    },
    [room, user, client, loading]
  );

  const leaveRoom = useCallback(
    () => {
      setErrorMessage("");
      if (loading) return;
      if (room) {
        log.info("ColyseusRoomProvider:", "leaveRoom >", room.id);
        room.removeAllListeners();
        room.leave();
        setRoom(null);
        // setRoomState(null);
      }
    },
    [room, loading]
  );

  return (
    <RoomContext.Provider value={{room, /*roomState,*/ joinRoom, leaveRoom, errorMessage, roomLoading: loading}} {...props} />
  );
}

const useColyseusRoom = () => useContext(RoomContext);

export { ColyseusRoomProvider, useColyseusRoom };
