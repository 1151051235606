import React, { useEffect, useState, useRef } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import log from "loglevel";
import classnames from "classnames";
import Slider from "react-slick";
import { useLobby } from "../context/LobbyProvider";
import { useColyseusRoom } from "../context/ColyseusRoomProvider";
import { useShow, SHOW_STATES } from "../context/ShowProvider";
import SmokeContainer from "./SmokeContainer";
import LinkNotice from "./LinkNotice";
import PhotoBoothNotice from "./PhotoBoothNotice";
import NotOpen from "./NotOpen";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../styles/navigation.scss";

const MESSAGE_TIMEOUT = 15000;
function RoomKey({ allRooms, enterRoom }) {
  const [submitted, setSubmitted] = useState("");
  const [error, setError] = useState("");
  const [valid, setValid] = useState(false);
  const timeoutId = useRef(null);
  const inputRef = useRef(null);

  const onSubmitRoomKey = async (e) => {
    e.preventDefault();
    setError("");
    setValid(false);
    const roomKey = inputRef.current.value;
    setSubmitted(roomKey);
    clearTimeout(timeoutId.current);
    timeoutId.current = null;

    const selectedRoom = allRooms.find((r) => r.metadata.key.toUpperCase() === roomKey.trim().toUpperCase());
    if (selectedRoom) {
      if (selectedRoom.metadata.open) {
        setValid(true);
        enterRoom(selectedRoom);
        timeoutId.current = setTimeout(clearRoomKey, MESSAGE_TIMEOUT);
      } else {
        setError(`${roomKey} is not open yet`);
        timeoutId.current = setTimeout(clearRoomKey, MESSAGE_TIMEOUT);
      }
    } else {
      setError(`${roomKey} is invalid`);
      timeoutId.current = setTimeout(clearRoomKey, MESSAGE_TIMEOUT);
    }
  };

  function clearRoomKey() {
    setSubmitted("");
    setError("");
    setValid(false);
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    }
  }, []);

  return (
    <div className="room-key-container m-auto">
      <form onSubmit={onSubmitRoomKey}>
        <label htmlFor="room-key">Select room above or enter code: 
          <div className="room-key-input">
            <div className="room-key-left">[</div>
            <input
              ref={inputRef}
              type="text"
              name="room-key"
              className={classnames({ 'text-red-400': error, 'text-green-400': valid })}
              autoComplete="off"
              autoFocus
              maxLength="12"
              placeholder="Enter Code"
            />
            <div className="room-key-right">]</div>
          </div>
        </label>
        { error &&
          <p className="room-key-invalid">{error}</p>
        }
        { valid &&
          <p className="room-key-valid">{submitted} is valid</p>
        }
        <input type="submit" value="Send" className="hidden" />
      </form>
    </div>
  );
}

function DoorImage({ room, showState }) {
  const imgRef = useRef();

  function setFallback() {
    if (imgRef.current) {
      imgRef.current.src = "https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/NULL.png";
    }
  }

  if (room.metadata.key === "MARY") {
    return (
      <img
        ref={imgRef}
        onError={setFallback}
        src={`https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/glitch-door.gif`}
        alt=""
      />
    );
  }

  return (
    <img
      ref={imgRef}
      onError={setFallback}
      src={`https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/${room.metadata.key.toLowerCase()}.jpg`}
      alt=""
    />
  );
}

function Door({ r, idx, showState, enterRoom }) {
  if (!r || !r.metadata) return null;
  return (
    <div className={classnames("door", {
        "open": r.metadata.open,
        "new": r.metadata.open && r.metadata.host,
        "glitch": r.metadata.key === "MARY",
        "afterparty": showState === "Afterparty"
      })}>
      { r.metadata.open ?
        <button className="door-content" onClick={() => enterRoom(r)}>
          <div className="glow"></div>
          <div className="frame"></div>
          <div className="inner">
            <DoorImage room={r} showState={showState} />
            <div className="door-code">{r.metadata.key}</div>
          </div>
        </button> :
        <button className="door-content closed" disabled>
          <div className="glow"></div>
          <div className="frame"></div>
          <div className="inner">
            <DoorImage room={r} showState={showState} />
            <div className="door-code">{r.metadata.ended ? "ENDED" : "OPENING SOON"}</div>
          </div>
        </button>
      }
      
    </div>
  );
}

function DoorSlider({ showState, allRooms, rooms, currentKey, room, enterRoom }) {
  const [slideIdx, setSlideIdx] = useState(1);
  const slider = useRef();

  return (
    <SmokeContainer>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className="slider-vignette"></div>
      <div className="slider-container">
        <div>
          <div className="spacer"></div>
          { rooms.length > 0 &&
            <Slider
              key="SHOW"
              ref={slider}
              dots={false}
              arrows={true}
              infinite={true}
              accessibility={true}
              speed={300}
              initialSlide={rooms.length - 1}
              slidesToShow={Math.min(rooms.length, 5)}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: Math.min(rooms.length, 3),
                    slidesToScroll: 1
                  }
                }
              ]}
            >
              { rooms.map((r, idx) => (
                  <Door
                    key={r.roomId}
                    r={r}
                    idx={idx}
                    current={r.metadata.key === currentKey}
                    showState={showState}
                    enterRoom={enterRoom}
                  />
                ))}
            </Slider>
          }
          { rooms.length === 0 &&
            <div className="no-rooms-notice">
              <p>No rooms. You may have lost connection to the mainframe, please refresh.</p>
            </div>
          }
          <div className="room-key-wrapper">
            <RoomKey allRooms={allRooms} enterRoom={enterRoom} />
          </div>
        </div>
        <div className="prev-blocker"></div>
        <div className="next-blocker"></div>
      </div>
      <img className="neon-ring" src="https://cdn.chorusproductions.com/eschaton-v2/navigation/room-open-ring.png" alt="Eschaton rooms now open" />
    </SmokeContainer>
  );
}

function Navigation() {
  const { show } = useShow();
  const { allRooms, showState } = useLobby();
  const { joinRoom, leaveRoom } = useColyseusRoom();
  const [room, setRoom] = useState(null);
  const [showRooms, setShowRooms] = useState(allRooms.filter((r) => !r.metadata.door_hidden && !r.metadata.afterparty && r.metadata.key !== show.finale_room).sort((a, b) => {
    if (a.metadata.host && !b.metadata.host) return -1;
    if (!a.metadata.host && b.metadata.host) return 1;
    return 0;
  }));
  const [showBackupLink, setShowBackupLink] = useState(false);
  const windowRef = useRef();

  function openLink(room) {
    if (room && ["performer", "external"].indexOf(room.name) > -1) {
      windowRef.current = window.open(room.metadata.link, "_blank");
      if (!windowRef.current) {
        log.warn("Navigation", "Pop up failed to open");
        setShowBackupLink(true);
      } else {
        setShowBackupLink(false);
      }
      if (room.name === "performer") {
        setTimeout(() => {
          if (windowRef.current) {
            windowRef.current.close();
            windowRef.current = null;
          }
        }, 10000);
      }
    }
  }

  async function enterRoom(room) {
    log.info("Navigation: enterRoom", room.roomId);
    if (room.metadata.open) {
      openLink(room);
      try {
        await joinRoom(room.roomId);
        setRoom(room);
      } catch (err) {
        log.error("Navigation: enterRoom", err);
        setRoom(null);
      }
    }
  }

  //
  // update various views when allRooms changes
  //
  useEffect(() => {
    setShowRooms(allRooms.filter((r) => !r.metadata.door_hidden && !r.metadata.afterparty && r.metadata.key !== show.finale_room).sort((a, b) => {
      if (a.metadata.host && !b.metadata.host) return -1;
      if (!a.metadata.host && b.metadata.host) return 1;
      return 0;
    }));
  }, [allRooms, show]);

  useEffect(() => {
    leaveRoom();
  }, []);

  //
  // appearing/disappearing puzzle room
  //
  useEffect(() => {
    let appearInterval, disappearInterval;
    const fameRoom = allRooms.find((r) => r.metadata && r.metadata.key === "MARY");
    if (fameRoom && !appearInterval && !disappearInterval) {
      appearInterval = setInterval(() => {
        setShowRooms(s => ([
          ...s,
          fameRoom
        ]));
        disappearInterval = setTimeout(() => {
          setShowRooms(s => s.filter((r) => r.metadata.key !== "MARY"));
        }, 30 * 1000);
      }, 90 * 1000);
    }

    return () => {
      if (appearInterval) clearInterval(appearInterval);
      if (disappearInterval) clearTimeout(disappearInterval);
    }
  }, []);

  if (showState === "Closed") {
    log.info("Navigation", "Show closed, redirecting to entrance.");
    return <Redirect to="/" />;
  }

  if (showState === "Preshow") {
    log.info("Navigation", "Show not open yet.");
    return <NotOpen />;
  }

  if (showState === "Finale") {
    const finaleRoom = allRooms.find((r) => r.metadata && r.metadata.key === show.finale_room);
    return (
      <>
        <Finale room={finaleRoom} enterRoom={enterRoom} />
        { showBackupLink && finaleRoom && <LinkNotice link={finaleRoom.metadata.link} name={finaleRoom.metadata.key} setVisible={setShowBackupLink} /> }
      </>
    );
  }

  if (showState === "Afterparty") {
    return (
      <>
        <Afterparty
          showState={showState}
          allRooms={allRooms}
          room={room}
          enterRoom={enterRoom}
        />
        { showBackupLink && room && <LinkNotice link={room.metadata.link} name={room.metadata.key} setVisible={setShowBackupLink} /> }
      </>
    );
  }

  if (room && ["fame","puzzle"].indexOf(room.name) > -1) {
    return <Redirect to={`/${room.name}/${room.roomId}`} push={true} />;
  }

  // show
  return (
    <>
      <DoorSlider
        showState={showState}
        allRooms={allRooms}
        rooms={showRooms}
        room={room}
        enterRoom={enterRoom}
      />
      { showBackupLink && room && <LinkNotice link={room.metadata.link} name={room.metadata.key} setVisible={setShowBackupLink} /> }
    </>
  );
}

function Afterparty({ showState, allRooms, enterRoom }) {
  const [showPoseNotice, setShowPoseNotice] = useState(false);
  const slider = useRef();

  const rooms = allRooms.filter((r) => !r.metadata.door_hidden && r.metadata.afterparty);
  const pose = allRooms.find((r) => r.metadata.door_hidden && r.metadata.key === "POSE");
  
  return (
    <SmokeContainer>
      <Helmet>
        <title>Afterparty</title>
      </Helmet>
      <div className="slider-vignette"></div>
      <div className="slider-container afterparty-slider">
        <div>
          <div className="spacer"></div>
          <Slider
            key="AFTERPARTY"
            ref={slider}
            className="center"
            centerMode={true}
            centerPadding="10px"
            dots={false}
            arrows={true}
            infinite={false}
            accessibility={true}
            draggable={true}
            slidesToShow={rooms.length}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: Math.min(rooms.length, 3),
                  slidesToScroll: 1
                }
              }
            ]}
          >
            { rooms.map((r, idx) => (
                <Door
                  key={r.roomId}
                  r={r}
                  idx={idx}
                  showState={showState}
                  enterRoom={enterRoom}
                />
              ))}
          </Slider>
          <div className="room-key-wrapper">
            <RoomKey allRooms={allRooms} enterRoom={enterRoom} />
          </div>
        </div>
      </div>
      <img className="neon-ring" src="https://cdn.chorusproductions.com/eschaton-v2/navigation/afterparty-ring.png" alt="Eschaton Afterparty" />
      { pose &&
        <div className="photographer-icon">
          <button className="neon-icon" title="Strike A Pose" onClick={() => setShowPoseNotice(true)}>
            <i className="fal fa-camera-retro"></i>
          </button>
        </div>
      }
      { showPoseNotice &&
        <PhotoBoothNotice setVisible={setShowPoseNotice} link={pose.metadata.link} />
      }
    </SmokeContainer>
  );
}

function Finale({ room, enterRoom }) {
  return (
    <SmokeContainer>
      <Helmet>
        <title>
          Finale
        </title>
      </Helmet>
      <div className="slider-vignette"></div>
      <div className="">
        <div>
          <div className="spacer"></div>
          <div className="finale-door new open">
            <button className="door-content" onClick={() => enterRoom(room)}>
              <div className="finale-frame"></div>
              <div className="inner">
                <video
                  muted
                  autoPlay
                  loop
                  className="portal-video"
                  src="https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/FINALE.mp4"
                  poster="https://cdn.chorusproductions.com/eschaton-v2/navigation/doors/FINALE.jpg"
                />
                <div className="door-code">This way to the end...</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <img className="neon-ring" src="https://cdn.chorusproductions.com/eschaton-v2/navigation/finale-ring.png" alt="Enter Finale" />
    </SmokeContainer>
  );
}

export default withRouter(Navigation);
