import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import FinaleNotice from "../FinaleNotice";
import BackstageZoom from "./BackstageZoom";
import Stage0 from "./Stage0";
import "../../styles/puzzles.scss";
import "../../styles/sound.scss";


// dot - 1 unit
// dash - 3 units
// space between letters is 3 units
// space between words is 7 units
const RIDDLE="fog";
const MORSE = {
  a: ".-",
  b: "-...",
  c: "-.-.",
  d: "-..",
  e: ".",
  f: "..-.",
  g: "--.",
  h: "....",
  i: "..",
  j: ".---",
  k: "-.-",
  l: ".-..",
  m: "--",
  n: "-.",
  o: "---",
  p: ".--.",
  q: "--.-",
  r: ".-.",
  s: "...",
  t: "-",
  u: "..-",
  v: "...-",
  w: ".--",
  x: "-..-",
  y: "-.--",
  z: "--..",
};
const FRAMES = [];
FRAMES.push(-1);
FRAMES.push(-1);
FRAMES.push(-1);
FRAMES.push(-1);
FRAMES.push(-1);
FRAMES.push(0);
FRAMES.push(0);
FRAMES.push(0);
FRAMES.push(0);
FRAMES.push(0);
for (let i = 0; i < RIDDLE.length; i++) {
  const char = RIDDLE[i];
  if (char === " ") {
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
  } else {
    const pattern = MORSE[char];
    for (let j = 0; j < pattern.length; j++) {
      const unit = pattern[j];
      if (unit === ".") {
        FRAMES.push(1);
        FRAMES.push(0);
      }
      if (unit === "-") {
        FRAMES.push(1);
        FRAMES.push(1);
        FRAMES.push(1);
        FRAMES.push(0);
      }
    }
    // 3 units between letters
    FRAMES.push(0);
    FRAMES.push(0);
    FRAMES.push(0);
  }
}

function OnAirSign() {
  const canvas = useRef();

  useEffect(() => {
    let count = 7;

    function onLoad(evt) {
      count -= 1;
      if (count <= 0) {
        rafId = requestAnimationFrame(animation);
      }
    }

    const glitch1Src = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/glitch1.png";
    const glitch1Img = new Image();
    glitch1Img.onload = onLoad;
    glitch1Img.src = glitch1Src;
    const glitch2Src = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/glitch2.png";
    const glitch2Img = new Image();
    glitch2Img.onload = onLoad;
    glitch2Img.src = glitch2Src;
    const glitch3Src = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/glitch3.png";
    const glitch3Img = new Image();
    glitch3Img.onload = onLoad;
    glitch3Img.src = glitch3Src;
    const glitch4Src = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/glitch4.png";
    const glitch4Img = new Image();
    glitch4Img.onload = onLoad;
    glitch4Img.src = glitch4Src;
    const glitch5Src = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/glitch5.png";
    const glitch5Img = new Image();
    glitch5Img.onload = onLoad;
    glitch5Img.src = glitch5Src;

    const onSrc = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/on-air-on.png";
    const onImg = new Image();
    onImg.onload = onLoad;
    onImg.src = onSrc;

    const offSrc = "https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/on-air-off.png";
    const offImg = new Image();
    offImg.onload = onLoad;
    offImg.src = offSrc;

    let ctx, rafId;
    let lastStamp = 0, delta = 0;
    let lastOn = null;
    let frame = 0;

    function animation(timestamp) {
      delta = timestamp - lastStamp;

      const frameIdx = Math.floor(frame / 15) % FRAMES.length;
      const on = FRAMES[frameIdx];

      if (lastOn !== on) {
        if (on < 0) {
          const frameNum = Math.floor(frame / 5) % FRAMES.length;
          const glitchFrame = frameNum % 5;
          const step = glitchFrame; // Math.abs(on);
          if (step === 0) ctx.drawImage(glitch1Img, 0, 0);
          if (step === 1) ctx.drawImage(glitch2Img, 0, 0);
          if (step === 2) ctx.drawImage(glitch3Img, 0, 0);
          if (step === 3) ctx.drawImage(glitch4Img, 0, 0);
          if (step === 4) ctx.drawImage(glitch5Img, 0, 0);
        } else if (on === 1) {
          ctx.drawImage(onImg, 0, 0);
        } else {
          ctx.drawImage(offImg, 0, 0);
        }
        lastOn = on;
      }

      lastStamp = timestamp;
      frame += 1;

      rafId = requestAnimationFrame(animation);
    }

    if (canvas.current) {
      ctx = canvas.current.getContext("2d");
    }

    return () => {
      if (rafId) cancelAnimationFrame(rafId);
    };
  }, []);

  return (
    <div className="on-air" title="On Air">
      <canvas ref={canvas} width="276px" height="88px"></canvas>
    </div>
  );
}

const tracks = [
  {
    src: "https://cdn.chorusproductions.com/eschaton-v2/audio/lobby.mp3",
    downloadSrc: "https://cdn.chorusproductions.com/eschaton-v2/audio/lobby_download.mp3",
    filename: "Lobby.mp3",
    title: "Lobby"
  },
  {
    src: "https://cdn.chorusproductions.com/eschaton-v2/audio/Spuds_Remix.mp3",
    downloadSrc: "https://cdn.chorusproductions.com/eschaton-v2/audio/Spuds_Remix_download.mp3",
    filename: "Spuds_Remix.mp3",
    title: "Spuds Remix"
  },
  {
    src: "https://cdn.chorusproductions.com/eschaton-v2/audio/earthquake.mp3",
    downloadSrc: "https://cdn.chorusproductions.com/eschaton-v2/audio/earthquake_download.mp3",
    filename: "earthquake.mp3",
    title: "Earthquake"
  }
];

function ComputerTerminal() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [code, setCode] = useState("");
  const [messageComplete, setMessageComplete] = useState(false);
  const [incorrectCode, setIncorrectCode] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [showMaryTxt, setShowMaryTxt] = useState(false);
  const [showCrewList, setShowCrewList] = useState(false);
  const [currentMusic, setCurrentMusic] = useState(null);

  function checkCode(evt) {
    evt.preventDefault();
    if (code.toLowerCase().trim() === "stars") {
      setLoggedIn(true);
    } else {
      setIncorrectCode(true);
    }
  }

  function onShowHint(evt) {
    evt.preventDefault();
    setShowHint(true);
  }

  function handleChange(evt) {
    setIncorrectCode(false);
    setCode(evt.target.value);
  }

  useEffect(() => {
    if (showMaryTxt) {
      setShowCrewList(false);
      setCurrentMusic(null);
      setMessageComplete(true);
    }
  }, [showMaryTxt]);

  useEffect(() => {
    if (showCrewList) {
      setShowMaryTxt(false);
      setCurrentMusic(null);
    }
  }, [showCrewList]);

  useEffect(() => {
    if (currentMusic) {
      setShowMaryTxt(false);
      setShowCrewList(false);
    }
  }, [currentMusic]);

  return (
    <div className="screen-close terminal">
      <img
        alt=""
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/screen.png"
      />
      { !loggedIn &&
        <div className="terminal-login">
          {/*<img
            alt=""
            src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/form-bg.png"
            className="retro-window-bg"
          />*/}
          <form onSubmit={checkCode}>
            <label className="terminal-input" name="terminal-password">
              <div className="prompt">
                Enter password
              </div>
              <div>
                <input type="text" name="terminal-password" maxLength="12" autoFocus autoComplete="off" onChange={handleChange} />
                <div className={classnames("invalid", { transparent: !incorrectCode })}>Invalid Password</div>
              </div>
            </label>
            <button type="button" title="Forgot your password?" className="forgot-btn" onClick={onShowHint}>Forgot your password?</button>
            { showHint && <div className={classnames("hint", { show: showHint })}>
              Yvkd oxwwbj olysx ioxp, koyvuzh lfwr jjkwmjfm. Uyvkwkoxs, zmse ffk qcyy, aoqsy fkgd.
            </div> }
            <div>
              <input type="submit" value="Log in" className="retro-btn" />
            </div>
          </form>
        </div>
      }
      { loggedIn &&
        <div className="desktop">
          <div className="icon-container">
            <button className="desktop-icon glitch-icon" title="Mary.txt" onClick={() => setShowMaryTxt(true)}>
              <img
                alt="SOS.txt"
                className="file"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/sos.png"
              />
              <img
                alt="SOS.txt"
                className="file-glitch"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/sos-glitch.gif"
              />
            </button>
            <button className={classnames("desktop-icon", { "disabled": !messageComplete })} disabled={!messageComplete} title="Crewlist.pdf" onClick={() => setShowCrewList(true)}>
              <img
                alt="crewlist.txt"
                className="file"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/crewlist.png"
              />
            </button>
            <button className="desktop-icon" title="Lobby.mp3" onClick={() => setCurrentMusic(tracks[0])}>
              <img
                alt="lobby.mp3"
                className="file"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/lobby_mp3.png"
              />
            </button>
            <button className="desktop-icon" title="Spuds_Remix.mp3" onClick={() => setCurrentMusic(tracks[1])}>
              <img
                alt="Spuds_Remix.mp3"
                className="file"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/spuds_mp3.png"
              />
            </button>
            <button className="desktop-icon" title="earthquake.mp3" onClick={() => setCurrentMusic(tracks[2])}>
              <img
                alt="earthquake.mp3"
                className="file"
                src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/earthquake_mp3.png"
              />
            </button>
          </div>

          { showMaryTxt &&
            <Window
              title="sos.txt"
              onClose={() => setShowMaryTxt(false)}
              glitch
            >
              <p className="desktop-text">
                I’m putting this message in a bottle hoping someone will listen. Please help me. If you’ve found this note, it means you’ve read my notebook and followed the trail I left. I was a star of Eschaton, but I asked too many questions. They fired me...but that’s only the beginning! This all sounds so crazy. It's been so long since I had someone to talk to. Maybe I am crazy. But, they took something from me. Something I didn't even know I could give. I'm lost without it. Now I can’t get back to Eschaton to reclaim what’s mine. I need someone who can help me. PLEASE. I only signed the contract because I thought it would be safe. You have to find it. Not just for me, but for ALL THE PERFORMERS. The Janitor will know where they keep it all. He knows where everything is in Eschaton. Find it and set me free. Set us all free. You're all I have left. MH
                <span className="blinking-cursor">|</span>
              </p>
            </Window>
          }
          
          { showCrewList &&
            <Window
              title="crewlist.txt"
              onClose={() => setShowCrewList(false)}
            >
              <ul className="crewlist">
                <li>Hostess - Liza Temset: <span className="redacted">redacted</span></li>
                <li>Resident Reader - Jack Van Ness: <span className="redlisted">redlisted</span></li>
                <li>Janitor - Freddy Ziegler: <span className="redacted-glitch">freddyzig16@gmail.com</span></li>
                <li>Security - Libby Johnson: <span className="redacted">redacted</span></li>
              </ul>
            </Window>
          }

          { currentMusic &&
            <MusicPlayer
              key={currentMusic.src}
              src={currentMusic.src}
              downloadSrc={currentMusic.downloadSrc}
              title={currentMusic.title}
              filename={currentMusic.filename}
              onClose={() => setCurrentMusic(null)}
            />
          }
        </div>
      }
    </div>
  );
}

function Window({ title, onClose, glitch, children }) {
  const windowRef = useRef();
  const menuRef = useRef();

  // useEffect(() => {
  //   if (menuRef.current) {
  //     menuRef.current.addEventListener("mousemove", (evt) => {
  //       if (evt.buttons === 1) {
  //         // console.log(evt);
  //         // console.log(windowRef.current);
  //         if (Math.abs(evt.movementX) > Math.abs(evt.movementY)) {
  //           if (evt.movementX < 0) {
  //             windowRef.current.style.left = `${Math.max(0, windowRef.current.offsetLeft - 10)}px`;
  //           } else {
  //             windowRef.current.style.left = `${windowRef.current.offsetLeft + 10}px`;
  //           }
  //         } else {
  //           if (evt.movementY < 0) {
  //             windowRef.current.style.top = `${Math.max(0, windowRef.current.offsetTop - 10)}px`;
  //           } else {
  //             windowRef.current.style.top = `${windowRef.current.offsetTop + 10}px`;
  //           }
  //         }
  //       }
  //     });
  //   }
  // }, []);

  return (
    <div ref={windowRef} className={classnames("window", { "window-glitch": glitch })}>
      <div ref={menuRef} className="top-bar">
        {title}
        <button title="Close Window" onClick={onClose}>X</button>
      </div>
      { children }
    </div>
  );
}

function MusicPlayer({ src, downloadSrc, title, filename, onClose }) {
  const [track, setTrack] = useState(null);
  const [trackDuration, setTrackDuration] = useState(0);
  const [trackTime, setTrackTime] = useState(0);
  const [trackPlaying, setTrackPlaying] = useState(false);

  function getTimerString(secondsRemaining) {
    if (secondsRemaining <= 0) return '00:00';
    let hours = secondsRemaining / 3600;
    let minutes = (hours % 1) * 60;
    let seconds = (minutes % 1) * 60;
    let HH = hours < 10 ? "0" + Math.floor(hours) : Math.floor(hours);
    let MM = minutes < 10 ? "0" + Math.floor(minutes) : Math.floor(minutes);
    let SS = seconds < 10 ? "0" + Math.floor(seconds) : Math.floor(seconds);

    if (hours < 1) {
      return `${MM}:${SS}`;
    }

    return `${HH}:${MM}:${SS}`;
  }

  function toggle() {
    if (trackPlaying) {
      track.pause();
      setTrackPlaying(false);
    } else {
      track.play();
      setTrackPlaying(true);
    }
  }

  function handleSeek(evt) {
    const seekPosition = evt.target.value;
    track.currentTime = seekPosition;
  }

  useEffect(() => {
    const audio = new Audio(src);
    setTrack(audio);

    audio.addEventListener("loadeddata", () => setTrackDuration(audio.duration.toFixed(0)));
    audio.addEventListener("timeupdate", () => setTrackTime(audio.currentTime.toFixed(0)));

    return () => {
      audio.pause();
      setTrack(null);
      setTrackDuration(0);
      setTrackTime(0);
    };
  }, []);

  return (
    <Window
      title={filename}
      onClose={onClose}
    >
      <div className="music-player desktop-text">
        <div className="music-btn-container">
          { trackPlaying
            ? <button title="Pause" onClick={toggle}>|| PAUSE</button>
            : <button title="Play" onClick={toggle}>&gt; PLAY</button>
          }
        </div>
        <div className="music-info-container">
          <div className="music-info">
            <div>{title}</div><div>{getTimerString(trackTime)}/{getTimerString(trackDuration)}</div>
          </div>
          <div className="seek-container">
            <div className="seek-bar"></div>
            <input
              type="range"
              min="0"
              max={trackDuration}
              value={trackTime}
              onChange={handleSeek}
              className="seek-control"
            />
          </div>
          <div>
            <a
              className="music-download"
              href={downloadSrc}
              title="Download"
              download={title}
            >
              Download
            </a>
          </div>
        </div>
      </div>
    </Window>
  );
}

function BackButton({ stage, setStage }) {
  if (stage === 0) {
    return (<div></div>);
  }

  if (stage === 1) {
    return (
      <div className="back-buttons">
        <Link className="btn-back continue" data-text="HOME" to="/home">HOME</Link>
      </div>
    );
  }

  return (
    <div className="back-buttons">
      <Link className="btn-back continue" data-text="BACK" to="#" onClick={() => setStage(stage - 1)}>BACK</Link>
    </div>
  );
}

function Gizmos() {
  return (
    <>
      <div className="static-tiled"></div>
      <img
        className="sound-booth-wide"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/soundbooth_bg_old.png"
      />
      {/* sliders left */}
      <div className="slider slider-1">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-2">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-3">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-4">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-5">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
    {/* sliders right */}
      <div className="slider slider-6">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-7">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-8">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-9">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      <div className="slider slider-10">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/slider.png" alt="" />
      </div>
      {/* top knobs */}
      <img
        className="knob knob-1"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/red-knob.png"
        alt=""
      />
      <img
        className="knob knob-2"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/blue-knob.png"
        alt=""
      />
      <img
        className="knob knob-3"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/blue-knob.png"
        alt=""
      />
      <img
        className="knob knob-4"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/blue-knob.png"
        alt=""
      />
      <img
        className="knob knob-5"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/red-knob.png"
        alt=""
      />
      {/* bottom knobs */}
      <img
        className="knob knob-6"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/blue-knob.png"
        alt=""
      />
      <img
        className="knob knob-7"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/blue-knob.png"
        alt=""
      />
      <img
        className="knob knob-8"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/red-knob.png"
        alt=""
      />
      <img
        className="knob knob-9"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/red-knob.png"
        alt=""
      />
      <img
        className="knob knob-10"
        src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/red-knob.png"
        alt=""
      />
    </>
  );
}

export default function SoundBooth() {
  const [stage, setStage] = useState(0);

  return (
    <div className="puzzle-container">
      <Helmet>
        <title>SOUND</title>
      </Helmet>
      { /* stage 0 */ }
      <div className={classnames("puzzle-content", { enter: stage === 0 })}>
        { stage === 0 && <Stage0 name="Sound Booth" stage={stage} setStage={setStage} /> }
      </div>

      { /* stage 1 */ }
      <div className={classnames("puzzle-content", { enter: stage === 1 })}>
        <BackstageZoom />
        { stage === 1 && <Gizmos /> }

        { stage === 1 && <OnAirSign /> }

        { stage === 1 &&
          <button className="terminal-button" title="Computer Terminal" onClick={() => setStage(2)}>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/sound_booth/screen_wideview.png" />
          </button>
        }
      </div>

      { /* stage 2 */ }
      <div className={classnames("puzzle-content", { enter: stage === 2 })}>
        <BackstageZoom />
        <div className="static-tiled"></div>
        { stage === 2 && <ComputerTerminal /> }
      </div>

      <BackButton stage={stage} setStage={setStage} />

      <FinaleNotice />
    </div>
  );
};
