import React from "react";
import ReactDOM from "react-dom";
import log from "loglevel";
import "./styles/styles.css";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./context/AuthProvider";
import { UserProvider } from "./context/UserProvider";
import { ShowProvider } from "./context/ShowProvider";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "development") {
  log.setDefaultLevel("debug");
} else {
  log.disableAll();
}

function MobileNotice() {
  return (
    <div className="mobile-notice">
      <div className="overlay"></div>
      <div className="logo">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/neon_logo_on.png" className="logo on" alt="ESCHATON is Open" draggable="false" />
      </div>
      <p>Unfortunately, Eschaton does not support mobile browsers.</p>
      <br />
      <p>Please join from a laptop or desktop computer.</p>
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ShowProvider>
        <AuthProvider>
          <UserProvider>
            <App />
            <MobileNotice />
          </UserProvider>
        </AuthProvider>
      </ShowProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
