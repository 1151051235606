import React, { /*useState,*/ useEffect } from "react";
// import classnames from "classnames";
import "../styles/modal.scss";

export default function LinkNotice({ name, link, setVisible }) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [setVisible]);

  return (
    <div className="finale-notice console">
      <div className="overlay"></div>
      <div className="modal visible">
        <div className="inner">
          <p>Zoom may not have opened if you have pop-ups blocked.</p>
          <p>
            <a
              className="link"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setVisible(false)}
            >
              Click here
            </a>&nbsp;to enter {name}.
          </p>
          <button className="close" title="Close Notice" onClick={() => setVisible(false)}>
            x
          </button>
        </div>
      </div>
    </div>
  );
}