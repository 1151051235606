import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import log from "loglevel";
import { useShow } from "../context/ShowProvider";
import "../styles/modal.scss";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.tz.setDefault("America/New_York");

export default function FinaleNotice() {
  const { show, getMsUntil } = useShow();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (show && !timeoutId) {
      const msUntilNotice = getMsUntil(dayjs(show.finale_time).tz(show.timezone).subtract(3, "minute"));
      if (msUntilNotice > -(1000 * 60 * 3)) {
        timeoutId = setTimeout(() => {
          log.debug("FinaleNotice: 3 minutes until finale.");
          setVisible(true);
        }, msUntilNotice);
      }
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    }
  }, [show, getMsUntil]);

  return (
    <div className="finale-notice console">
      <div className="overlay"></div>
      <div className={classnames("modal", { "visible": visible })}>
        <div className="inner">
          <p>It's almost time for the finale.</p>
          <p>
            <Link className="link" to="/home">Click here</Link>&nbsp;to return home;
          </p>
          <button className="close" title="Close Notice" onClick={() => setVisible(false)}>
            x
          </button>
        </div>
      </div>
    </div>
  );
}