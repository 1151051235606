import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import log from "loglevel";
import classnames from "classnames";
import { Stream } from "@cloudflare/stream-react";
import { useShow } from "../context/ShowProvider";
import "../styles/lobby.scss";
import "../styles/modal.scss";

function CountdownTimer({ isOpen, setIsOpen, handleEnter }) {
  const { show, getMsUntil } = useShow();
  const countdown = useRef();

  // set up countdown timer
  useEffect(() => {
    let rafId;
    function getTimerString(msRemaining) {
      if (msRemaining <= 0) return '00:00:00';
      const secondsRemaining = msRemaining / 1000;
      let hours = secondsRemaining / 3600;
      let minutes = (hours % 1) * 60;
      let seconds = (minutes % 1) * 60;
      let HH = hours < 10 ? "0" + Math.floor(hours) : Math.floor(hours);
      let MM = minutes < 10 ? "0" + Math.floor(minutes) : Math.floor(minutes);
      let SS = seconds < 10 ? "0" + Math.floor(seconds) : Math.floor(seconds);
      return `${HH}:${MM}:${SS}`;
    }

    function anim(timestamp) {
      const timeRemainingMs = getMsUntil(show.play);
      const timerString = getTimerString(timeRemainingMs);
      if (countdown.current) {
        countdown.current.innerHTML = timerString;
      } else {
        cancelAnimationFrame(rafId);
      }
      if (timeRemainingMs >= 0) {
        rafId = requestAnimationFrame(anim);
      } else {
        setIsOpen(true);
      }
    }

    if (countdown.current) {
      rafId = requestAnimationFrame(anim);
    }

    return () => {
      cancelAnimationFrame(rafId);
    }
  }, [show, getMsUntil, setIsOpen]);

  if (isOpen) {
    return (
      <button title="Enter" onClick={handleEnter}>
        <img src="/images/enter-ring.png" alt="Enter" />
      </button>
    );
  }

  return (
    <div className="countdown">
      <span align="center" ref={countdown}></span>
    </div>
  );
}

// function Tutorial({ visible, onDismiss }) {
//   const [step, setStep] = useState(0);

//   return (
//     <React.Fragment>
//       { visible && <div className="tutorial-overlay"></div> }
//       <div className={classnames("tutorial modal", { visible: visible })}>
//         <div className="inner">
//           <div className={classnames("tutorial-text", { "hidden": step !== 0 })}>
//             <p>
//               This is your Eschaton chatbox here on the right-hand side of your screen.
//             </p>
//             <button className="tutorial-next" onClick={() => setStep(1)} title="Next Step">Next &gt;</button>
//           </div>
//           <div className={classnames("tutorial-text", { "hidden": step !== 1 })}>
//             <p>
//               It is important to keep the chatbox visible during the whole show as other guests and Eschaton staff will communicate with you via this chatbox.
//             </p>
//             <button className="tutorial-next" onClick={() => setStep(2)} title="Next Step">Next &gt;</button>
//           </div>
//           <div className={classnames("tutorial-text", { "hidden": step !== 2 })}>
//             <p>
//               Please note that you can only chat with other guests who are in the same room as you - that is, if you are in the lobby you cannot chat with someone who is in one of the performance rooms.
//             </p>
//             <button className="tutorial-next" onClick={() => setStep(3)} title="Next Step">Next &gt;</button>
//           </div>
//           <div className={classnames("tutorial-text", { "hidden": step !== 3 })}>
//             <p>
//               Click the arrow <i className="fal fa-external-link-alt"></i> in the corner to pop it out now.
//             </p>
//             <button className="tutorial-next" onClick={onDismiss} title="Close Tutorial">Close</button>
//           </div>
//           <div className="tutorial-steps">
//             <button title="Go to tutorial step 1" onClick={() => setStep(0)}>
//               <div className={classnames("step", { "current": step === 0 })}></div>
//             </button>
//             <button title="Go to tutorial step 2" onClick={() => setStep(1)}>
//               <div className={classnames("step", { "current": step === 1 })}></div>
//             </button>
//             <button title="Go to tutorial step 3" onClick={() => setStep(2)}>
//               <div className={classnames("step", { "current": step === 2 })}></div>
//             </button>
//             <button title="Go to tutorial step 4" onClick={() => setStep(3)}>
//               <div className={classnames("step", { "current": step === 3 })}></div>
//             </button>
//           </div>
//           <button className="close" title="Close Tutorial" onClick={onDismiss}>
//             x
//           </button>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

export default function Lobby() {
  const { show, getMsUntil } = useShow();
  // const { sound, musicPlaying } = useSound();
  const [isOpen, setIsOpen] = useState(false);
  // const [showVideo, setShowVideo] = useState(true);
  // const [showOnboarding, setShowOnboarding] = useState(false);
  // const [onboardingComplete, setOnboardingComplete] = useState(false);
  // const [curtainSound, setCurtainSound] = useState(null);
  const [muted, setMuted] = useState(true);
  const [showMusicButton, setShowMusicButton] = useState(false);
  const [entered, setEntered] = useState(false);
  const [videoComplete, setVideoComplete] = useState(false);
  // const [musicPlaying, setMusicPlaying] = useState(false);
  const [triedToPlayMusic, setTriedToPlayMusic] = useState(false);
  const streamPlayerRef = useRef();
  const musicRef = useRef();
  const curtainRef = useRef();

  function handleEnter(evt) {
    // if (sound && sound.playing()) sound.pause();
    if (musicRef.current && audioPlaying()) musicRef.current.pause();
    setEntered(true);
    // curtainSound.play();
    if (curtainRef.current) curtainRef.current.play();
    if (streamPlayerRef.current) streamPlayerRef.current.play();
  }

  function toggleCC(evt) {
    console.log("toggleCC");
    if (streamPlayerRef.current) {
      const track = streamPlayerRef.current.textTracks[0];
      track.mode = track.mode === "hidden" ? "showing" : "hidden";
      log.info("Lobby:", "toggleCC", track.mode);
    }
  }

  function audioPlaying() {
    if (musicRef.current) {
      return !musicRef.current.paused || musicRef.current.currentTime;
    }
    return false;
  }

  function mute() {
    if (musicRef.current) {
      musicRef.current.muted = true;
      setMuted(true);
    }
  }

  function unmute() {
    if (musicRef.current) {
      const timeRemainingSec = getMsUntil(show.play) / 1000;
      if (timeRemainingSec > 0) {
        musicRef.current.muted = false;
        setMuted(false);
        const duration = musicRef.current.duration;
        const musicPosition = duration - (timeRemainingSec % duration);
        musicRef.current.currentTime = musicPosition;
        log.info("Lobby", "unmute", "Music position", musicPosition);
        if (musicRef.current.paused) {
          musicRef.current.play();
        }
      }
    }
  }

  // useEffect(() => {
  //   if (!curtainSound) {
  //     const swish = new Howl({
  //       src: ["sounds/curtains.mp3"],
  //       html5: true,
  //       preload: true
  //     });
  //     setCurtainSound(swish);
  //   }

  //   return () => {

  //   };
  // }, [curtainSound]);

  useEffect(() => {
    if (isOpen) {
      if (musicRef.current) {
        musicRef.current.pause();
        musicRef.current.muted = true;
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (musicRef.current) {
      musicRef.current.addEventListener("loadeddata", (evt) => {
        log.debug("Lobby", "Music event", "loadeddata");
        setShowMusicButton(true);
        if (!audioPlaying()) {
          const timeRemainingSec = getMsUntil(show.play) / 1000;
          if (timeRemainingSec > 0) {
            const duration = musicRef.current.duration;
            const musicPosition = duration - (timeRemainingSec % duration);
            musicRef.current.currentTime = musicPosition;
            log.info("Lobby", "Music position", musicPosition);
            // if (!audioPlaying() && !triedToPlayMusic) {
            //   musicRef.current.play();
            //   setTriedToPlayMusic(true);
            // }
            if (!triedToPlayMusic) {
              musicRef.current.play();
              setTriedToPlayMusic(true);
            }
          }
        }
      });

      musicRef.current.addEventListener("play", (evt) => {
        setMuted(false);
      });
    }
  }, []);

  if (videoComplete) {
    return <Redirect to="/interstitial" />;
  }

  return (
    <div className="lobby-container">
      <Helmet>
        <title>Eschaton Lobby</title>
      </Helmet>
      <div className="overlay"></div>
      <audio
        ref={curtainRef}
        src="https://cdn.chorusproductions.com/eschaton-v2/lobby/ei_curtain.mp3"
        preload="auto"
      ></audio>
      <audio
        ref={musicRef}
        src="https://cdn.chorusproductions.com/eschaton-v2/lobby/lobby.mp3"
        preload="metadata"
      ></audio>
      <div className="container-inner">
        <div className="content">
          <div className="video-container">
            <div className={classnames("video-stream", { "hidden": !entered })}>
              <Stream
                streamRef={streamPlayerRef}
                controls
                src="b7066fcd6f1fc97ffa461b49cc42385b"
                onEnded={() => setVideoComplete(true)}
                onError={(err) => log.error("Lobby:", err)}
                onStalled={() => log.warn("Lobby:", "Intro video playback stalled.")}
              />
            </div>
          </div>

          <div className={classnames("curtain left", { open: entered })}>
            <div className="star">
              <img src="/images/star-left.png" alt="" />
            </div>
          </div>

          <div className={classnames("curtain right", { open: entered })}>
            <div className="star">
              <img src="/images/star-right.png" alt="" />
            </div>
          </div>

          <div className="center">
            { !entered &&
              <CountdownTimer isOpen={isOpen} setIsOpen={setIsOpen} handleEnter={handleEnter} />
            }
          </div>

          <div className={classnames("figure left", { open: entered })}>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-left1.png" className="anim1" alt=""/>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-left2.png" className="anim2" alt=""/>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-left3.png" className="anim3" alt=""/>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-left4.png" className="anim4" alt=""/>
          </div>
          
          <div className={classnames("figure right", { open: entered })}>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-right1.png" className="anim1" alt="" />
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-right2.png" className="anim2" alt="" />
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/figure-right3.png" className="anim3" alt="" />
          </div>

          <div className="top">
            <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/procenium-top.png" className="procenium" alt="" /> 
            { isOpen ?
                <img src="/images/live-on.png" className="live on" alt="Dim neon sign LIVE" /> :
                <img src="/images/live-off.png" className="live off" alt="Lit neon sign LIVE" />
            }
          </div>

          <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/procenium-side.png" className="sidepiece left" alt="" />
          <img src="https://cdn.chorusproductions.com/eschaton-v2/lobby/procenium-side.png" className="sidepiece right" alt="" />

        </div>

        {/* videoComplete && !showOnboarding && <div className={classnames("btn-container", { "on": videoComplete && !showOnboarding })}>
            <button className="btn-forward continue" data-text="CONTNUE" onClick={() => setShowOnboarding(true)} title="Continue">CONTINUE</button>
          </div>
        */}

      </div>

      { !isOpen && !entered && /*showMusicButton &&*/
        <div className="lobby-music-buttons">
          { !muted &&
            <button className="neon-icon" title="Lobby Music Off" onClick={mute}>
              <i className="fal fa-volume-up"></i>
            </button>
          }
          { muted &&
            <button className="neon-icon" title="Lobby Music On" onClick={unmute}>
              <i className="fal fa-volume-mute"></i>
            </button>
          }
        </div>
      }

      {/* entered &&
        <div className="lobby-cc-button">
          <button className="neon-icon" title="Closed captions on" onClick={toggleCC}>
            <i className="fal fa-closed-captioning"></i>
          </button>
        </div>
      */}
    </div>
  );
};
