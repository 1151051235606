import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Typing from "../React-Typing-Animation";
import SmokeContainer from "../SmokeContainer";

export default function Stage0({ name, stage, setStage }) {
  const [typingComplete, setTypingComplete] = useState(false);

  return (
    <SmokeContainer>
      <audio
        src="https://cdn.chorusproductions.com/eschaton-v2/typing/ei_drone2.mp3"
        preload="auto"
        autoPlay
        loop
      ></audio>
      <div className="typewriter-text liminal">
        <div className="console">
          <Typing speed={80} className="typing" onFinishedTyping={() => setTypingComplete(true)}>
            <br />
            <Typing.Delay ms={500} />
            You saucy minx, slipping backstage...<br/><br/>
            <Typing.Delay ms={700} />
            Welcome to the {name}.
            <Typing.Delay ms={500} />
          </Typing>
        </div>
      </div>
      <div className={classnames("btn-container", { "on": typingComplete })}>
        <Link className="btn-forward continue" data-text="CONTINUE" to="#" onClick={() => setStage(stage + 1)} title="Continue">CONTINUE</Link>
      </div>
    </SmokeContainer>
  );
}