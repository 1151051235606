import React, { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Typing from "./React-Typing-Animation";
import SmokeContainer from "./SmokeContainer";
import "../styles/login.scss";

export default function Interstitial() {
  const [typingComplete, setTypingComplete] = useState(false);

  return (
    <SmokeContainer>
      <audio
        src="https://cdn.chorusproductions.com/eschaton-v2/typing/ei_drone2.mp3"
        preload="auto"
        autoPlay
        loop
      ></audio>
      <div className="typewriter-text">
        <div className="console">
          <Typing onFinishedTyping={() => setTypingComplete(true)} speed={60} className="typing">
            Come on in. You’re about to enter the Homepage.<br/>
            Click a door or type a code to enter a Zoom room.<br/>
            And get ready to turn your cameras on...<br /><br />
            <Typing.Delay ms={1500} />
            It’s only good if we can see your eyes.
          </Typing>
        </div>
      </div>
      { typingComplete && <div className={classnames("btn-container", { "on": typingComplete })}>
          <Link className="btn-forward continue" data-text="CONTINUE" to="/home" title="Continue">CONTINUE</Link>
        </div>
      }
    </SmokeContainer>
  );
};
