import React, { useState, useEffect } from "react";
import log from "loglevel";
import Spinner from "../components/Spinner";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [existingToken, setExistingToken] = useState(localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME));

  useEffect(() => {
    async function checkToken(token) {
      log.info("AuthProvider:", "checkToken");
      setLoading(true);
      try {
        const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/check_token`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        if (rawResponse.status === 200) {
          const user = await rawResponse.json();
          log.debug("AuthProvider:", "checkToken", "token valid");
          log.debug("AuthProvider:", "checkToken", user);
          setData(d => ({
            ...d,
            user: {
              ...user,
              token: token
            }
          }));
        } else {
          log.debug("AuthProvider:", "checkToken", "token invalid");
          localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
          setExistingToken(null);
          setData({});
        }
        // return rawResponse.status;
      } catch (err) {
        log.error("AuthProvider:", "checkToken", err);
        // localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
        setExistingToken(null);
        // return 500;
      } finally {
        setLoading(false);
      }
    }

    if (existingToken) {
      log.debug("AuthProvider:", "existing token");
      checkToken(existingToken);
    } else {
      log.debug("AuthProvider:", "no existing token");
      setLoading(false);
    }

    // return () => {
    //   log.debug("AuthProvider:", "Unmount");
    // }

  }, [existingToken]);

  const checkCode = async (code) => {
    log.info("AuthProvider:", "checkCode");
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/check_code`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code })
      });
      log.debug("AuthProvider:", "checkCode", rawResponse.status);
      return rawResponse.status;
    } catch (err) {
      log.error("AuthProvider:", "checkCode", err);
      return 500;
    }
  };

  const checkName = async (name) => {
    log.info("AuthProvider:", "checkName");
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/check_name`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      });
      log.debug("AuthProvider:", "checkName", rawResponse.status);
      return rawResponse.status;
    } catch (err) {
      log.error("AuthProvider:", "checkName", err);
      return 500;
    }
  };

  const login = async (nickname, code) => {
    log.info("AuthProvider:", "login", nickname);
    try {
      const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: nickname, code })
      });
      const json = await rawResponse.json();
      if (json && json.token) {
        log.debug("AuthProvider:", "login successful");
        setData(d => ({
          ...d,
          user: { ...json }
        }));
        localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_NAME, json.token);
      } else {
        log.warn("AuthProvider:", "login did not receive token");
      }
      return rawResponse.status;
    } catch (err) {
      log.error("AuthProvider:", "login", err);
      return 500;
    }
  };

  const logout = () => {
    log.info("AuthProvider:", "logout");
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
    setData({});
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  return (
    <AuthContext.Provider value={{data, login, logout, checkCode, checkName}} {...props} />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
