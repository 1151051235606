import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import { useShow, SHOW_STATES } from "../context/ShowProvider";
import SmokeContainer from "./SmokeContainer";
import "../styles/entrance.scss";

const Entrance = () => {
  const { show, state } = useShow();

  function signIsOn() {
    return state === SHOW_STATES.PRESHOW || state === SHOW_STATES.OPEN || state === SHOW_STATES.FINALE;
  }

  return (
    <div className={classnames({"show-closed": !signIsOn(), "show-open": signIsOn() })}>
      <Helmet>
        <title>Eschaton</title>
      </Helmet>
      <SmokeContainer>
        <div className="logo">
          <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/neon_logo_on.png" className="logo on" alt="ESCHATON is Open" draggable="false" />
          <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/neon_logo_bg.png" className="logo off" alt="ESCHATON is Closed" draggable="false" />
          {/*<Link to="/vault" className="neon-flicker">
            <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/s-normal.png" className="normal" alt="" draggable="false" />
            <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/s-bright.png" className="bright" alt="" draggable="false" />
          </Link>*/}
          <div className="neon-flicker">
            <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/s-normal.png" className="normal" alt="" draggable="false" />
            <img src="https://cdn.chorusproductions.com/eschaton-v2/entrance/s-bright.png" className="bright" alt="" draggable="false" />
          </div>
        </div>
        <div className="show-info">
          { show && <p className="showtime">SHOWTIME | {show.startFormatted}</p>}
          <div className="entrance-buttons">
            { signIsOn() ? 
              <Link
                to="/enter"
                className="show-link enter"
                title="Enter Now"
                data-text="ENTER NOW"
              >
                Enter Now
              </Link> :
              <span
                className="show-link enter"
                data-text="ENTER NOW"
              >
                Enter Now
              </span>
            }
            <a
              className="show-link tickets"
              data-text="GIFT SHOP"
              title="Gift Shop"
              href="https://info.eschaton.club/merch" target="_blank" rel="noopener noreferrer"
            >
              Gift Shop
            </a>
            <a
              className="show-link tickets"
              data-text="INFO"
              title="Get Tickets"
              href="https://info.eschaton.club" target="_blank" rel="noopener noreferrer"
            >
              Info
            </a>
          </div>
        </div>
      </SmokeContainer>
    </div>
  );
}

export default Entrance;
