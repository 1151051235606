import React from "react";
import "../styles/smoke.css";

const SmokeContainer = ({ children }) => (
  <div className="smoke-container">
    <video loop muted autoPlay poster="https://cdn.chorusproductions.com/eschaton-v2/video/smoke.jpg">
      <source src="https://cdn.chorusproductions.com/eschaton-v2/video/looping_smoke_no_mask.webm" type="video/webm" />
      <source src="https://cdn.chorusproductions.com/eschaton-v2/video/looping_smoke_no_mask.m4v" type="video/mp4" />
    </video>
    <div className="video-mask"></div>
    <div className="overlay"></div>
    <div className="smoke-content">
      { children }
    </div>
  </div>
);

export default SmokeContainer;