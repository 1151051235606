import React, { createContext, useContext, useState, useEffect } from "react";
import * as Colyseus from "colyseus.js";
import log from "loglevel";
import Spinner from "../components/Spinner";


const ColyseusContext = createContext(null);

function ColyseusClientProvider (props) {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (!client) {
      log.info("ColyseusClientProvider:", "create");
      const newClient = new Colyseus.Client(process.env.REACT_APP_COLYSEUS_WS_ENDPOINT);
      setClient(newClient);
    } else {
      setLoading(false);
    }

  }, [client, setClient, setLoading]);

  if (loading) {
    return <div className="spinner-full"><Spinner /></div>;
  }

  return (
    <ColyseusContext.Provider value={client} {...props} />
  );
}

const useColyseusClient = () => useContext(ColyseusContext);

export { ColyseusClientProvider, useColyseusClient };
