import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { init } from "../../js/forest";
import { fairyDustCursor } from "../../js/sparkleCursor";

import "../../styles/puzzles.scss";
import "../../styles/forest.scss";

function Tree({ id }) {
  const [active, setActive] = useState(false);
  let hoverTimeout = useRef();

  function handleMouseEnter(e) {
    hoverTimeout.current = setTimeout(() => {
      setActive(true);
    }, 1500);
  }

  function handleMouseLeave(e) {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
  }

  return (
    <ul className={classnames(id, { off: !active })}>
      <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  );
}

export default function Forest() {
  useEffect(() => {
    fairyDustCursor();
    init();
  }, []);

  return (
    <div className="forest-root">
      <div className="container">
        <Tree id="left" />
        <Tree id="center" />
        <Tree id="right" />
      </div>
    </div>
  );
}