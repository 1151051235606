import React, { useState, useRef, useEffect } from "react";
import NewWindow from "react-new-window";
import { Link } from "react-router-dom";
import classnames from "classnames";
import log from "loglevel";
// import { useColyseusClient } from "../context/ColyseusClientProvider";
import { useColyseusRoom } from "../context/ColyseusRoomProvider";
import { useChat } from "../context/ChatProvider";
// import { useLobby } from "../context/LobbyProvider";
import { useShow, SHOW_STATES } from "../context/ShowProvider";
import { useUser } from "../context/UserProvider";
import "../styles/chat.scss";
import "../styles/emojis.scss";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.tz.setDefault("America/New_York");

//
// Chat pop out window wrapper
//
function ChatWrapper(props) {
  const [chatPopped, setChatPopped] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const timeoutId = useRef(null);
  const chatWindow = useRef(null);

  const togglePop = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    setChatPopped(!chatPopped);
  }

  const onBlock = () => {
    setBlocked(true);
    timeoutId.current = setTimeout(function() {
      setBlocked(false);
    }, 7000);
  };

  const onUnload = () => {
    setChatPopped(false);
  };

  useEffect(() => {
    if (chatPopped && chatWindow.current) {
      chatWindow.current.window.resizeTo(300, window.innerHeight);
      window.onunload = function() {
        if (chatWindow.current && chatWindow.current.window && !chatWindow.current.window.closed) {
          chatWindow.current.window.close();
        }
      }
    }
  }, [chatPopped]);

  if (chatPopped) {
    return (
      <NewWindow
        name="Eschaton Chat"
        title="Eschaton Chat"
        ref={chatWindow}
        onBlock={onBlock}
        onUnload={onUnload}
        features={{
          width: 300,
          left: "1000px",
          menubar: "no",
          toolbar: "no",
          resizable: false
        }}
      >
        <Chat togglePop={togglePop} isPopped={chatPopped} {...props} />
      </NewWindow>
    );
  }

  return (
    <React.Fragment>
      <Chat togglePop={togglePop} isPopped={chatPopped} {...props} />
      { blocked &&
        <div className="absolute right-0" style={{ width: '300px', top: '35px' }}>
          <p className="m-auto bg-red-700 text-white text-center">Pop up was blocked.</p>
        </div>
      }
    </React.Fragment>
  );
}

//
// Chat component
//
function Chat({ togglePop, isPopped }) {
  const user = useUser();
  const { state } = useShow();
  // const { lobby, /*chatState, sendChat, sendDM, getDMRoom, setAtNotifications, setDmNotifications*/ } = useLobby();
  const { room } = useColyseusRoom();
  const { chatRoom, chatState, sendChat, sendDM, getDMRoom, setAtNotifications, setDmNotifications } = useChat();
  const [tab, setTab] = useState("ROOM");
  const [dmScope, setDmScope] = useState(null);
  const [dmRoom, setDmRoom] = useState(null);

  /*
   * handleDMScopeChange
   * when choosing a name from DMs, try to join the room if we don't already have it stored
   */
  const handleDMScopeChange = async (recipientId) => {
    const recipient = chatState.attendees.find((a) => a.id === recipientId);
    log.info("Chat:", "handleDMScopeChange", `DMs ${user.displayName} -> ${recipient.displayName}`);
    const nots = chatState.dmNotifications.slice();
    const idx = nots.indexOf(recipientId);
    nots.splice(idx, 1);
    setDmNotifications(nots);
    chatRoom.send("clear-dm", recipientId);
    const dr = await getDMRoom(recipientId);
    setDmRoom(dr);
    setDmScope(recipient);
  };

  /*
   * onSubmit
   * when sending a chat message, send the appropriate room messages depending on current scope
   */
  const onSubmit = async (message) => {
    message = message.trim();
    if (message.length === 0) return;

    // send message to appropriate room
    if (tab === "ROOM") {
      log.debug("Chat:", "onSubmit", `${user.displayName} > Lobby: ${message}`);
      sendChat(message);
      // chatRoom.send("chat", message);
    } else if (tab === "DMS") {
      if (dmRoom) {
        // existing room w/ messages
        log.debug("Chat:", "onSubmit", `${user.displayName} > ${dmScope.displayName}: ${message}`);
        sendDM(message, dmScope.id);
      } else {
        log.warn("Chat:", "onSubmit fail", `${user.displayName} > ${dmScope.displayName}: ${message}`);
      }
    }
  };

  useEffect(() => {
    setDmScope("");
  }, [tab]);

  useEffect(() => {
    setTab("ROOM");
    setDmScope("");
  }, []);

  useEffect(() => {
    if (chatRoom && tab === "ROOM") {
      chatRoom.send("clear-at");
    }
  }, [tab, chatRoom]);

  // no chat room
  if (!chatRoom) {
    return (
      <div className={classnames("chat-container disabled", { "in": !isPopped })}>
        <ChatHeader roomName="Backstage" />
        <ChatTabs
          disabled={true}
          isPopped={isPopped}
          togglePop={togglePop}
          tab={tab}
          setTab={setTab}
          atNotifications={chatState.atNotifications}
          dmNotifications={chatState.dmNotifications}
        />
        <div className="chat-block">
          <div className="overlay"></div>
          <p>Oh dear, you've lost connection to the Mainframe.</p>
          <p>Please refresh.</p>
        </div>
      </div>
    );
  }

  // management disables communication in puzzles
  if ((room && ["puzzle","fame"].indexOf(room.name) > -1) ||
      (window.location.pathname.indexOf("puzzle") > -1) ||
      (window.location.pathname.indexOf("fame") > -1)) {
    return (
      <div className={classnames("chat-container disabled", { "in": !isPopped })}>
        <ChatHeader roomName="Backstage" />
        <ChatTabs
          disabled={true}
          isPopped={isPopped}
          togglePop={togglePop}
          tab={tab}
          setTab={setTab}
          atNotifications={chatState.atNotifications}
          dmNotifications={chatState.dmNotifications}
        />
        <div className="chat-block">
          <div className="overlay"></div>
          <p>It's all quiet...</p>
          <p>Management has disabled communication in this area.</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames("chat-container", { "in": !isPopped })}>
      {/* HEADER */}
      <ChatHeader />
      {/* TABS */}
      <ChatTabs
        isPopped={isPopped}
        togglePop={togglePop}
        tab={tab}
        setTab={setTab}
        atNotifications={chatState.atNotifications}
        dmNotifications={chatState.dmNotifications}
        roomId={chatRoom.id}
      />
      {/* ANNOUNCEMENTS */}
      {/* state === SHOW_STATES.PRESHOW && <Announcements count={chatState.count} /> */}
      {/* CHAT CONTENT */}
      { tab === "ROOM" &&
        <React.Fragment>
          <ChatMessages user={user} messages={chatState.messages} />
          <ChatFooter displayName={user.displayName} onSubmit={onSubmit} />
        </React.Fragment>
      }
      { tab === "DMS" && !dmScope &&
        <AttendeesList user={user} count={chatState.count} attendees={chatState.attendees} dmNotifications={chatState.dmNotifications} handleDMScopeChange={handleDMScopeChange} />
      }
      { tab === "DMS" && dmScope &&
        <React.Fragment>
          <div className="dm-header">
            <div className="overlay"></div>
            <button onClick={() => setDmScope(null)} title="Back to DMs">
              <i className="fal fa-chevron-left"></i>
            </button>
            <span>@{dmScope.displayName}</span>
          </div>
          <ChatMessages user={user} messages={chatState.dmMessages[dmScope.id]} isEschatonStaff={dmScope.displayName === "Eschaton Staff"} />
          <ChatFooter displayName={user.displayName} onSubmit={onSubmit} />
        </React.Fragment>
      }
      { tab === "HELP" &&
        <FAQs />
      }
    </div>
  );
};

//
// Chat FAQs/help tab
//
function FAQs() {
  const [open, setOpen] = useState({ 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
  return (
    <div className="chat-message-container faq">
      <ul>
        <li>
          <button title="Where am I?" onClick={() => setOpen({...open, 0: 1 - open[0]})}>
            <strong>Where am I? {open[0] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[0] &&
            <p>Welcome to Eschaton. The universe’s  preeminent virtual nightclub. The Management is thrilled to offer you endless rooms featuring talent from around the globe.  There’s no wrong way to enjoy your hour in Eschaton, so feel free to explore at your leisure. And, when your hour in Eschaton’s rooms runs out, meet us back on the Homepage to celebrate our Grand Finale.</p>
          }
        </li>
        <li>
          <button title="What is the Mainframe and how do I use it?" onClick={() => setOpen({...open, 1: 1 - open[1]})}>
            <strong>What is the Mainframe and how do I use it? {open[1] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[1] &&
            <React.Fragment>
              <p>The mainframe is your communications portal while exploring Eschaton. Here you’re encouraged to celebrate the show with the rest of the audience, spark up a private conversation with a fellow guest, and reach out directly to communicate with the Eschaton Staff.  This is also where the Management of Eschaton will communicate with you as needed throughout the experience. You will still be able to chat directly with our performers within the Zoom chat.</p>
              <p>Curious things are always happening in Eschaton, so be sure to keep an eye on your Mainframe throughout the night.</p>
            </React.Fragment>
          }
        </li>
        <li>
          <button title="How do I know where to go?" onClick={() => setOpen({...open, 2: 1 - open[2]})}>
            <strong>How do I know where to go? {open[2] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[2] &&
            <p>There is no one correct path. Return to the homepage and click a new door to go to a new zoom room. Eschaton is limitless, click as much as your heart desires.</p>
          }
        </li>
        <li>
          <button title="How do I get into Zoom?" onClick={() => setOpen({...open, 3: 1 - open[3]})}>
            <strong>How do I get into Zoom? {open[3] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[3] &&
            <p>Click a door on the homepage. It will take you to a Zoom room.</p>
          }
        </li>
        <li>
          <button title="Still lost or confused?" onClick={() => setOpen({...open, 4: 1 - open[4]})}>
            <strong>Still lost or confused? {open[4] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[4] &&
            <p>For help, please DM @EschatonStaff with your question.</p>
          }
        </li>
        <li>
          <button title="What is the optimum viewing experience?" onClick={() => setOpen({...open, 5: 1 - open[5]})}>
            <strong>What is the optimum viewing experience? {open[5] ? <i className="fal fa-angle-down"></i> : <i className="fal fa-angle-right"></i>}</strong>
          </button>
          { !!open[5] &&
            <p>We recommend fully maximizing the the Homepage to fill your screen. Be sure to keep your phone nearby, just in case.</p>
          }
        </li>
      </ul>
    </div>
  );
}

//
// Chat header
//
function ChatHeader() {
  return (
    <div className="chat-header">
      <div className="overlay"></div>
      <Link to="/home">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/common/mainframe.png" alt="Mainframe" />
      </Link>
    </div>
  );
}

//
// Chat tabs
//
function ChatTabs({ disabled, isPopped, togglePop, tab, setTab, atNotifications, dmNotifications, roomId }) {
  return (
    <div className="chat-tabs">
      <div className="overlay"></div>
      <div className="bump"></div>
      <div className="tab-wrapper">
        <button
          className={classnames({
            "selected": tab === "ROOM",
            "on": atNotifications.indexOf(roomId) > -1 && tab !== "ROOM",
            "disabled": disabled
          })}
          disabled={disabled}
          title="Room Chat"
          onClick={() => setTab("ROOM")}
        >
          <i className="fal fa-comment-alt"></i>
        </button>
        <button
          className={classnames({
            "selected": tab === "DMS",
            "on": dmNotifications.length > 0 && tab !== "DMS",
            "disabled": disabled
          })}
          disabled={disabled}
          title="Direct Messages"
          onClick={() => setTab("DMS")}
        >
          <i className="fal fa-user-friends"></i>
        </button>
        <button
          className={classnames({
            "selected": tab === "HELP",
            "disabled": disabled
          })}
          disabled={disabled}
          title="Help"
          onClick={() => setTab("HELP")}
        >
          <i className="fal fa-question-circle"></i>
        </button>
        <button
          title={`Pop chat ${isPopped ? 'back in' : 'out to new window'}`}
          onClick={togglePop}
        >
          <i className={classnames("fal fa-external-link-alt", { "chat-popped": isPopped })}></i>
        </button>
      </div>
      <div className="bump"></div>
    </div>
  );
}

//
// Chat announcement bar
//
function Announcements({ count }) {
  return (
    <div className="chat-announcements">
      <p>{`${count} ${count === 1 ? 'person is' : 'people are'} here`}</p>
    </div>
  );
}

//
// Attendee list
//
function AttendeesList({ user, count, attendees, dmNotifications, handleDMScopeChange }) {
  const [filter, setFilter] = useState("");
  const [filteredAttendees, setFilteredAttendees] = useState(attendees);

  useEffect(() => {
    if (filter.length > 0) {
      const filtered = attendees
                        .filter((attendee) => {
                          const name = attendee.displayName.toLowerCase().replace(/^\s+$/g, '');
                          const f = filter.toLowerCase().replace(/^\s+$/g, '');
                          return name.indexOf(f) > -1
                        });
      setFilteredAttendees(filtered);
    } else {
      setFilteredAttendees(attendees);
    }
  }, [filter, attendees]);

  return (
    <React.Fragment>
      <p className="note">Click a name to send a direct message</p>
      <div className="attendee-filter">
        <label htmlFor="attendee-filter">
          <input
            type="text"
            name="attendee-filter"
            value={filter}
            placeholder="Search by attendee name"
            onChange={(evt) => setFilter(evt.target.value)}
          />
          <div className="filter-icon">
            <div className="overlay"></div>
            <i className="fal fa-search"></i>
          </div>
        </label>
      </div>
      { filteredAttendees.length > 0 && 
        <ul className="attendee-list">
          { filteredAttendees.map((attendee) => (
            <li key={attendee.id}>
              <button
                className={classnames("attendee", {
                  "has-message": dmNotifications.length > 0 && dmNotifications.indexOf(attendee.id) > -1,
                  "staff": attendee.displayName.toLowerCase().indexOf("eschaton") > -1
                })}
                onClick={() => handleDMScopeChange(attendee.id)}
              >
                {attendee.displayName}
              </button>
            </li>
          ))}
        </ul>
      }
      { count === 1 &&
        <React.Fragment>
          <p className="note">You are alone.</p>
        </React.Fragment>
      }
      { count > 1 && filteredAttendees.length === 0 &&
        <React.Fragment>
          <p className="note">No results.</p>
          <p className="note">Make sure their name is spelled correctly and they are still in the room.</p>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

//
// Chat message window
//
function ChatMessages({ user, messages, isEschatonStaff }) {
  const [initialScroll, setInitialScroll] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(true);
  const chatWindow = useRef();
  const messageEnd = useRef();

  const scrollToBottom = () => {
    if (messageEnd.current) {
      messageEnd.current.scrollIntoView(true);
    }
  };

  const insertCustom = (message) => {
    const messageString = message.message;
    if (messageString.indexOf("=@") > -1 || messageString.indexOf("+") > -1) {
      let result = [];
      let temp = messageString.split("=");
      for (let i = 0; i < temp.length; i++) {
        const chunk = temp[i];
        if (chunk[0] === "@") {
          result.push(<><span key={`${message.createdAt}-at-${i}`} className="at">{chunk}</span>&nbsp;</>);
        } else {
          if (chunk.indexOf("+") > -1) {
            let temp2 = chunk.split("+");
            for (let j = 0; j < temp2.length; j++) {
              const chunk2 = temp2[j];
              if (chunk2.length > 0) {
                let idx = CUSTOM_EMOJIS.indexOf(chunk2);
                if (idx > -1) {
                  result.push(<CustomEmoji key={`${message.createdAt}-emoji-${CUSTOM_EMOJIS[idx]}-${j}`} name={CUSTOM_EMOJIS[idx]} darkMode />);
                } else {
                  result.push(<span key={`${message.createdAt}-text-${j}`}>{temp2[j]}</span>);
                }
              }
            }
          } else {
            result.push(<span key={`${message.createdAt}-noemoji-${i}`}>{chunk}</span>);
          }
        }
      }
      return result;
    } else {
      return messageString;
    }
  };

  // on first load, scroll to bottom of messages
  // in the future, we only want to scroll when 
  // 1) sending a message or
  // 2) already at the bottom
  useEffect(() => {
    const chatIsAtBottom = () => {
      if (chatWindow.current && messageEnd.current) {
        const elem = chatWindow.current;
        return elem.scrollHeight - elem.scrollTop - elem.clientHeight <= messageEnd.current.clientHeight;
      }
      return false;
    };

    const onScroll = (evt) => {
      setScrollBottom(chatIsAtBottom());
    };

    if (chatWindow.current) {
      chatWindow.current.addEventListener('scroll', onScroll);
    }

    if (messages) {
      if (!initialScroll && messages.length) {
        scrollToBottom();
        setInitialScroll(true);
      }
    }

    if (messages && scrollBottom) {
      scrollToBottom();
    }

  }, [messages, chatWindow, scrollBottom, initialScroll]);

  return (
    <div ref={chatWindow} className="chat-message-container">
      { isEschatonStaff &&
        <div className="dm-staff">
          <p>You are messaging Eschaton Staff. It may take a few minutes to respond to your request. In the meantime, please check the "Help" tab to see if your question is answered there.</p>
        </div>
      }
      <ul>
        { !messages && 
          <li className="italic">Be the first to say something...</li> 
        }
        { messages && messages.map((message) => (
          <li key={message.createdAt || message.message} className={classnames("message-line", {
            "notice": message.message.indexOf(`@${user.displayName}`) > -1,
            "staff": message.displayName.toLowerCase().indexOf("eschaton") > -1,
            "unknown": message.displayName.toLowerCase().indexOf("unknown") > -1,
            "usher": message.displayName.toLowerCase().indexOf("lobby usher") > -1
          })}>
            <div className="message-header">
              <span>
                {message.displayName}
              </span>
              <span>
                {dayjs(message.createdAt).format("h:mmA")}
              </span>
            </div>
            <p className="message">{insertCustom(message)}</p>
          </li>
        ))}
        <li style={{ float:"left", clear: "both" }} ref={messageEnd}>
        </li>
      </ul>
    </div>
  );
}

const CUSTOM_EMOJIS = [
  "ded",
  "drink",
  "heart",
  "heartface",
  "key",
  "shade",
  "skull",
  "smile",
  "squid"
];

function CustomEmoji({ name, darkMode }) {
  return (
    <span
      key={name}
      className={classnames(`custom-emoji ${name}`, { dark: darkMode })}
      role="img"
      alt={`${name} emoji`}
    >
    </span>
  );
}

//
// Chat footer (chat input and emojis)
//
function ChatFooter({ displayName, onSubmit }) {
  const [chatMessage, setChatMessage] = useState('');
  const chatInput = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    chatMessage.trim();
    if (!chatMessage) return;
    onSubmit(chatMessage);
    setChatMessage("");
  };

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  const insertEmoji = (emoji) => (
    (evt) => {
      setChatMessage(chatMessage + emoji);
      evt.target.blur();
      chatInput.current.focus();
    }
  );

  return (
    <div className="chat-footer">
      <p className="user-name">{displayName}:</p>
      <form onSubmit={handleSubmit}>
        <textarea
          ref={chatInput}
          rows="2"
          style={{ resize: 'none' }}
          name="chat"
          value={chatMessage}
          onChange={e => setChatMessage(e.target.value)}
          onKeyUp={submitOnEnter}
          autoComplete="off"
          placeholder="Write something..."
        ></textarea>
        <div className="emoji-list">
          { CUSTOM_EMOJIS.map((emoji) => (
            <button key={emoji} type="button" className="emoji-button" title={`${emoji} emoji`} onClick={insertEmoji(`+${emoji}+`)}>
              <CustomEmoji name={emoji} />
            </button>
          ))}
        </div>
        <input type="submit" value="Send" className="hidden" />
      </form>
    </div>
  );
}

export default ChatWrapper;
