import React from "react";
import "../../styles/puzzles.scss";

export default function BackstageWide() {
  return (
    <React.Fragment>
      <div className="puzzle-vignette"></div>
      <div className="backstage-neon-ring zoom">
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/backstage-ring.png" alt="" />
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/backstage-ring-glow.png" className="glow" alt="" />
      </div>
      <div className="overlay"></div>
    </React.Fragment>
  );
}