import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SmokeContainer from "./SmokeContainer";
// import Typing from "./React-Typing-Animation";
import "../styles/login.scss";

const NotOpen = () => (
  <SmokeContainer>
    <Helmet>
      <title>Not Open Yet</title>
    </Helmet>
    <audio
      src="https://cdn.chorusproductions.com/eschaton-v2/typing/ei_drone2.mp3"
      preload="auto"
      autoPlay
      loop
    ></audio>
    <div className="typewriter-text">
      <div className="console">
        <p className="typing error">
          Error...<br />
          This area isn't open yet.<br />
          Please <Link className="link" to="/lobby">return to the Eschaton Lobby</Link>.
        </p>
      </div>
    </div>
  </SmokeContainer>
);

export default NotOpen;
