import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import log from "loglevel";
import { ColyseusClientProvider } from "./context/ColyseusClientProvider";
import { ColyseusRoomProvider } from "./context/ColyseusRoomProvider";
import { LobbyProvider } from "./context/LobbyProvider";
import { ChatProvider } from "./context/ChatProvider";
import { SoundProvider } from "./context/SoundProvider";
import { useShow, SHOW_STATES } from "./context/ShowProvider";
import { useUser } from "./context/UserProvider";
import NotFound from "./components/404";
import Entrance from "./components/Entrance";
import Chat from "./components/Chat";
import Lobby from "./components/Lobby";
import Login from "./components/Login";
import Interstitial from "./components/Interstitial";
import Navigation from "./components/Navigation";
import PuzzleRoom from "./components/PuzzleRoom";
import FameRoom from "./components/FameRoom";
import JanitorsCloset from "./components/puzzles/JanitorsCart";
import ManagementsOffice from "./components/puzzles/ManagementsOffice";
import Forest from "./components/puzzles/Forest";

function AppProviders({children}) {
  const user = useUser();
  const { show, state } = useShow();

  if (!user) {
    log.info("AppProviders", "Not logged in, redirecting to login.");
    return <Redirect to="/enter" />;
  }

  // TODO afterparty - check user ticket tier
  if (state === SHOW_STATES.CLOSED ||
     (state === SHOW_STATES.AFTERPARTY && 
     (show.afterparty_ticket_access.indexOf(user.role) < 0 ||
      show.afterparty_ticket_access.length === 0
    ))) {
    log.info("AppProviders", "Show closed, redirecting to entrance.");
    return <Redirect to="/" />;
  }

  return (
    <ColyseusClientProvider>
      <LobbyProvider>
        <ColyseusRoomProvider>
          <div className="w-screen h-screen flex flex-col justify-start items-center overflow-hidden">
            <div className="flex justify-start self-stretch h-full">
              <div className="relative w-full h-full page">
                {children}
              </div>
              <div>
                <ChatProvider><Chat /></ChatProvider>
              </div>
            </div>
          </div>
        </ColyseusRoomProvider>
      </LobbyProvider>
    </ColyseusClientProvider>
  );
}

function PuzzleWrapper({ children }) {
  return (
    <div className="w-screen h-screen flex flex-col justify-start items-center overflow-hidden">
      <div className="flex justify-start self-stretch h-full">
        <div className="relative w-full h-full page">
          {children}
        </div>
        <div style={{ minWidth: "300px" }}>
          <p className="white">CHAT PLACEHOLDER</p>
        </div>
      </div>
    </div>
  );
}

function AnimatedApp({ children, ...rest }) {
  const location = useLocation();
  return (
    <Switch location={location}>
      <Route exact path="/">
        <Entrance />
      </Route>
      <Route exact path="/enter">
        <Login />
      </Route>
      <Route exact path="/lobby">
        <AppProviders><Lobby /></AppProviders>
      </Route>
      <Route exact path="/interstitial">
        <AppProviders><Interstitial /></AppProviders>
      </Route>
      <Route exact path="/home">
        <AppProviders><Navigation /></AppProviders>
      </Route>
      <Route exact path="/puzzle/:id">
        <AppProviders><PuzzleRoom /></AppProviders>
      </Route>
      <Route exact path="/fame/:id">
        <AppProviders><FameRoom /></AppProviders>
      </Route>
      <Route exact path="/closet">
        <PuzzleWrapper><JanitorsCloset /></PuzzleWrapper>
      </Route>
      <Route exact path="/vault">
        <ManagementsOffice />
      </Route>
      <Route exact path="/forest">
        <Forest />
      </Route>
      <Route path="*">
        <AppProviders><NotFound /></AppProviders>
      </Route>
    </Switch>
  );
}

function App() {
  return (
    <Router>
      <AnimatedApp />
    </Router>
  );
}

export default App;
