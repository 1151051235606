import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";

import SmokeContainer from "../SmokeContainer";
import Typing from "../React-Typing-Animation";
import Forest from "./Forest";

import { init } from "../../js/eject";

import "../../styles/puzzles.scss";
import "../../styles/management.scss";
import "../../styles/eject.scss";

function Eject({ stage, setStage }) {
  const [end, setEnd] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const root = useRef();
  const video = useRef();

  useEffect(() => {
    if (root.current) {
      // threejs
      init(() => setEnd(true));
    }
  }, []);

  useEffect(() => {
    if (end) {
      video.current.play();
      // video.current.addEventListener('ended', () => setStage(stage + 1));
      video.current.addEventListener('ended', () => setVideoEnded(true));
    }
  }, [end]);

  return (
    <div ref={root}>
      <div className={classnames("tunnel", { on: end, over: videoEnded })}>
        <video ref={video} src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/SWOOSH.mp4" muted></video>
        <Skeleton />
      </div>
    </div>
  );
}

function Skeleton({ }) {
  const [showNote, setShowNote] = useState(false);
  const [ended, setEnded] = useState(false);

  if (ended) {
    return <Redirect to="/forest" />;
  }

  return (
    <div className="skeleton-scene">
      <div className="static-tiled"></div>
      <img className="skeleton" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/skeleton.png" />
      <button title="Message in a bottle" onClick={() => setShowNote(true)}>
        <img className="bottle" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/bottle.png" />
      </button>
      { showNote &&
        <div className="message-in-bottle">
          <div className="handwriting-sheila">
            <Typing speed={50} onFinishedTyping={() => setEnded(true)}>
              Hi there. I’m Sheila. It’s so great to finally meet you! I’ve been watching your journey and cheering you on. You’ve done such a good job! Helping a lost spirit find the truth - there is no greater calling. Especially when that spirit is one I used to call a friend. Unfortunately, the truth is not what you believe it to be. That bulb in your hand - it’s not Mary’s Talent. It’s something else entirely. Something sacred...the Audience Spark. Mary’s Talent is gone and also here always.
              <br />
              <Typing.Delay ms={500} />
              It’s easier to understand if you see it with your own two eyes! The Spark, the Talent, the Management, they are all part of the Forest, the membrane of Eschaton that encompasses all things. Her Talent, all the performers’ Talent, is part of the ecosystem, the exchange that keeps Eschaton alive. You can never undo your choice to come to Eschaton. But there is beauty in the dedication. See for yourself…
              <Typing.Delay ms={2000} />
            </Typing>
          </div>
        </div>
      }
    </div>
  );
}

function Stage0({ stage, setStage }) {
  const [typingComplete, setTypingComplete] = useState(false);

  return (
    <SmokeContainer>
      <audio
        src="https://cdn.chorusproductions.com/eschaton-v2/typing/ei_drone2.mp3"
        preload="auto"
        autoPlay
        loop
      ></audio>
      <div className="typewriter-text liminal">
        <div className="console">
          <Typing speed={80} className="typing" onFinishedTyping={() => setTypingComplete(true)}>
            <br />
            <Typing.Delay ms={500} />
            Well, well, well, look at you, making your way all over Eschaton. You’re quite the adventurer, I must hand it to you. If it were up to me, you’d have the run of the place. But it’s not. Sadly, I’m just the gatekeeper, and only those with the Answer can pass. Type the Answer and you shall pass.
            <Typing.Delay ms={500} />
            <br />
            <br />
            (TODO: type "The Answer" in ASCII)
            <Typing.Delay ms={500} />
          </Typing>
        </div>
      </div>
      <div className={classnames("btn-container", { "on": typingComplete })}>
        <Link className="btn-forward continue" data-text="CONTINUE" to="#" onClick={() => setStage(1)} title="Continue">CONTINUE</Link>
      </div>
    </SmokeContainer>
  );
}

export default function ManagementsOffice() {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (stage === 3) {
      setTimeout(() => setStage(4), 2000);
    }
  }, [stage, setStage]);

  return (
    <div className="puzzle-container">
      <Helmet>
        <title>VAULT</title>
      </Helmet>

      <div className={classnames("puzzle-content", { enter: stage === 0 })}>
        { stage === 0 && <Stage0 name="The Vault" stage={stage} setStage={setStage} /> }
      </div>

      <div className={classnames("puzzle-content", { enter: stage === 1 })}>
        <img className="bg" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/bulbs.png" />
        <img className="flicker" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/bulbs2.png" />
        <button className="glitch-bulb" title="??" onClick={() => setStage(2)}></button>
      </div>

      <div className={classnames("puzzle-content", { enter: stage === 2 })}>
        <img className="bg" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/wall.png" />
        <img className="flicker" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/wall2.png" />
        <button className="glitch-bulb-2" title="??" onClick={() => setStage(3)}></button>
      </div>

      <div className={classnames("puzzle-content", { enter: stage === 3 })}>
        <img className="bg" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/managements_office/unscrewed.png" />
        <button className="unscrewed" title="" onClick={() => {}}></button>
      </div>

      <div className={classnames("puzzle-content", { enter: stage === 4 })}>
        { stage === 4 && <Eject stage={stage} setStage={setStage} /> }
      </div>

      {/*<div className={classnames("puzzle-content", { enter: stage === 5 })}>
        <Skeleton stage={stage} setStage={setStage} />
      </div>*/}
    </div>
  );
}