import React, { /*useState,*/ useEffect } from "react";
// import classnames from "classnames";
import "../styles/modal.scss";

export default function PhotoBoothNotice({ link, setVisible }) {
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setVisible(false);
  //   }, 10000);

  //   return () => {
  //     if (timeoutId) clearTimeout(timeoutId);
  //   }
  // }, [setVisible]);

  return (
    <div className="finale-notice console">
      <div className="overlay"></div>
      <div className="modal visible">
        <div className="inner">
          <p>By going into this room you agree to be photographed with strangers. You will receive a code after the show with information on how to access your photos.</p>
          <div className="confirm">
            <button
              className="link"
              title="Cancel"
              onClick={() => setVisible(false)}
            >
              Cancel
            </button>
            <a
              className="link"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setVisible(false)}
            >
              Enter
            </a>
          </div>
          <button className="close" title="Close Notice" onClick={() => setVisible(false)}>
            x
          </button>
        </div>
      </div>
    </div>
  );
}