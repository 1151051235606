// Global variable
const defaultPerspective = '0px';
// Track the mouse movemont
let mouseX = 0;
let mouseY = 0;

let width = 9999;
let height = 9999;

export function init() {
  width = getWidth();
  height = getHeight();

  document.addEventListener('mousemove', updateMousePosition);
  window.addEventListener('resize', (evt) => {
    width = window.innerWidth
    height = window.innerHeight
  });

  setInterval(moveStuff, 66)
}

// Follow mouse movement
function updateMousePosition(e) {
  mouseX = e.pageX/width;
  mouseY = e.pageY/height;
}

function scaler(n) { return .5 + (n * n * .01); }
function scootch(n) {return (mouseX -.5) * 7 * n; }

let yShift = 4;
let xShift = 5;

function moveStuff() {
  document.querySelectorAll('.left li').forEach((el, i) => {
    el.style.transform = `scale(${scaler(i)}) translate(-${i * xShift}px, -${i * yShift}px) translateX(${scootch(i)}px)`;
  });
  document.querySelectorAll('.center li').forEach((el, i) => {
    el.style.transform = `scale(${scaler(i)}) translateY(-${i * yShift}px) translateX(${scootch(i)}px)`;
  });
  document.querySelectorAll('.right li').forEach((el, i) => {
    el.style.transform = `scale(${scaler(i)}) translate(${i * xShift}px, -${i * yShift}px) translateX(${scootch(i)}px)`;
  });
}

// this function return the corresponding angle for an x value
function getAngle(x) {
  return 180 - 360 * x;
}
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )
}
function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  )
}
