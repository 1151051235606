import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import FinaleNotice from "../FinaleNotice";
import BackstageZoom from "./BackstageZoom";
import Stage0 from "./Stage0";
import "../../styles/puzzles.scss";
import "../../styles/janitor.scss";

function BackButton({ stage, setStage }) {
  if (stage === 0) {
    return (<div></div>);
  }

  if (stage === 1) {
    return (
      <div className="back-buttons">
        <Link className="btn-back continue" data-text="HOME" to="/home">HOME</Link>
      </div>
    );
  }

  return (
    <div className="back-buttons">
      <Link className="btn-back continue" data-text="BACK" to="#" onClick={() => setStage(1)}>BACK</Link>
    </div>
  );
}

function TapeRecorder({ f1, f2 }) {
  const [f1Complete, setF1Complete] = useState(false);
  const [f2Complete, setF2Complete] = useState(false);
  const [track, setTrack] = useState(f1);
  const [sequence, setSequence] = useState("");
  const [sequenceComplete, setSequenceComplete] = useState(false);
  const timeout = useRef();

  function play(e) {
    track.current.play();
    if (!f1Complete) {
      // window.open("/freddy.txt");
      setF1Complete(true);
    } else {
      setSequence(sequence + e.target.title);
    }
  }

  function record(e) {
    if (f1Complete) setSequence(sequence + e.target.title);
  }

  function pause(e) {
    if (f1Complete) setSequence(sequence + e.target.title);
    track.current.pause();
  }

  function ff(e) {
    if (f1Complete) setSequence(sequence + e.target.title);
    const currentTime = track.current.currentTime;
    track.current.currentTime = Math.min(currentTime + 1, track.current.duration);
    track.current.play();
  }

  function rewind(e) {
    if (f1Complete) setSequence(sequence + e.target.title);
    const currentTime = track.current.currentTime;
    track.current.currentTime = Math.max(currentTime - 1, 0);
    track.current.play();
  }

  function ftp(e) {
    if (!f1Complete) {
      window.open("/freddy.txt");
    } else {
      window.open("/rewind.txt");
    }
  }

  useEffect(() => {
    if (sequence === "RewindPlayRewindPausePlay") {
      track.current.pause();
      setTrack(f2);
      f2.current.play();
      clearTimeout(timeout.current);
      setSequenceComplete(true);
    } else {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setSequence("");
      }, 2000);
    }
  }, [sequence]);

  useEffect(() => {
    if (sequenceComplete) {
      f2.current.play();
      // window.open("/rewind.txt");
      setF2Complete(true);
    }
  }, [sequenceComplete]);

  useEffect(() => {
    return () => {
      if (f1.current) f1.current.pause();
      if (f2.current) f2.current.pause();
    }
  }, []);

  return (
    <>
      <img className="wide" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/recorder.png" />
      <button className="sticker" title="Be Kind, Rewind" onClick={() => {}}>
        { !sequenceComplete && <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/be-kind-rewind.png" /> }
        { sequenceComplete && <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/be-kind-rewind-xx.png" /> }
        { !sequenceComplete && f1Complete &&
          <img className="sticker-glitch" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/be-kind-rewind-code.png" />
        }
      </button>
      <button className="recorder-button ftp" title="FTP" onClick={(e) => ftp(e)}></button>
      <button className="recorder-button record" title="Record" onClick={(e) => record(e)}></button>
      <button className="recorder-button rewind" title="Rewind" onClick={(e) => rewind(e)}></button>
      <button className="recorder-button play" title="Play" onClick={(e) => play(e)}></button>
      <button className="recorder-button ff" title="Fast Forward" onClick={(e) => ff(e)}></button>
      <button className="recorder-button pause" title="Pause" onClick={(e) => pause(e)}></button>
    </>
  );
}

function Clipboard() {
  const [page, setPage] = useState(0);
  return (
    <>
      <img className="wide" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/clipboard.png" />
      { page === 0 && 
        <div className="clipboard-page" title="Cleaning Schedule">
          <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/cleaning-schedule.png" />
          <button className="turn-page next" onClick={() => setPage(1)} title="Next Page">&gt;</button>
        </div>
      }
      { page === 1 && 
        <div className="clipboard-page" title="Performer Contract">
          <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/contract.png" />
          <button className="turn-page back" onClick={() => setPage(0)} title="Previous Page">&lt;</button>
        </div>
      }
    </>
  );
}

export default function JanitorsCart() {
  const [stage, setStage] = useState(0);
  const f1 = useRef();
  const f2 = useRef();

  /*
  
  if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))console.log("this easter egg is unavailable in safari...");else{const e=" 60[ 60[ 15.:3. 40[ 11-*%@2%@5%3#+-. 31[ 8-#@%*=2+:-+*4+-.:=*%#=:. 26[ 5.+@2*: 10+%2- 3.:=#%@%4#*2+2=-. 14[ 4-@2*. 12+@3 3.-:. =- .@2#-:=3*%#= 11[ 3.@2* 14#@3 12@3* :#* 2.+@*. 8[ 3%@# 3.-=: 8:=*- 9.2 :=*. +@2* 4%@*. 6[ 2=@2:.2#@4. 13.-. 5.2 . 3%@2# 3:@4* 5[ 2=@2 2:-.-. 25:=-.=%@*:. 2:+%.-@% 4[ 2.@2#2@2%*. 39. 2+@+ 3[ 3#@*:=+2-. 11. 26.-2 :@2 3[ 4#@+-+#%#*=-.2 2:2 27:2 5%@- 2[ 5+@*:=*#2-:-.*#2*= . 6+%# 14#@2= 4#@+ 2[ 6.#%=3-=+%@3*+2=. 6:+- 13=@3: 4%@= 2[ 8-%@#+2-:*#*#2%*=+*+=: 2:%@= 9.%@3= .-2*@2. 2[ 10:=*%#%*=2#@6%*2#%@2%: 4.=3 3-*=:.:*%@2= 3[ 14:=*%#3@2#-2+2#*4%3#. :=#+: 2. :2*%@2#. 4[ 19.2:-=+*2#2%3@3#:.:3-2. 2=#%#@2#: 6[ 34-+*%#*+=-=*%@2%*- 9[ 39:-=4-. 13[ 60[";let s=0,a="";for(;s<e.length;){let t=s+1,r=1;for(;!isNaN(parseInt(e[t],10));)t+=1,r=parseInt(e.substr(s+1,t+1),10);"["!==e[s]?a+=e[s].repeat(r):a+="\n",s=t}console.log(a)}
  
  */
  
  // useEffect(() => {
  //   let min = "";
  //   let count = 0;
  //   fetch("/ascii.txt")
  //     .then((r) => r.text())
  //     .then(text => {
  //       // console.log(text);
  //       // for (let i = 0; i < text.length; i++) {
  //       //   let char = text[i];
  //       //   if (i === 0) {
  //       //     count = 1;
  //       //   } else {
  //       //     if (char === text[i - 1]) {
  //       //       count += 1;
  //       //     } else {
  //       //       let lastChar = text[i - 1];
  //       //       if (lastChar === "\n") {
  //       //         lastChar = "[";
  //       //       }
  //       //       min += count > 1 ? lastChar + count : lastChar;
  //       //       count = 1;
  //       //     }
  //       //   }
  //       // }
  //       // console.log(min);
  //       const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  //       if (!isSafari) {
  //         const min = " 60[ 60[ 15.:3. 40[ 11-*%@2%@5%3#+-. 31[ 8-#@%*=2+:-+*4+-.:=*%#=:. 26[ 5.+@2*: 10+%2- 3.:=#%@%4#*2+2=-. 14[ 4-@2*. 12+@3 3.-:. =- .@2#-:=3*%#= 11[ 3.@2* 14#@3 12@3* :#* 2.+@*. 8[ 3%@# 3.-=: 8:=*- 9.2 :=*. +@2* 4%@*. 6[ 2=@2:.2#@4. 13.-. 5.2 . 3%@2# 3:@4* 5[ 2=@2 2:-.-. 25:=-.=%@*:. 2:+%.-@% 4[ 2.@2#2@2%*. 39. 2+@+ 3[ 3#@*:=+2-. 11. 26.-2 :@2 3[ 4#@+-+#%#*=-.2 2:2 27:2 5%@- 2[ 5+@*:=*#2-:-.*#2*= . 6+%# 14#@2= 4#@+ 2[ 6.#%=3-=+%@3*+2=. 6:+- 13=@3: 4%@= 2[ 8-%@#+2-:*#*#2%*=+*+=: 2:%@= 9.%@3= .-2*@2. 2[ 10:=*%#%*=2#@6%*2#%@2%: 4.=3 3-*=:.:*%@2= 3[ 14:=*%#3@2#-2+2#*4%3#. :=#+: 2. :2*%@2#. 4[ 19.2:-=+*2#2%3@3#:.:3-2. 2=#%#@2#: 6[ 34-+*%#*+=-=*%@2%*- 9[ 39:-=4-. 13[ 60[";
  //         let idx = 0;
  //         let str = "";
  //         while (idx < min.length) {
  //           let nextIdx = idx + 1;
  //           let count = 1;

  //           while (!isNaN(parseInt(min[nextIdx], 10))) {
  //             nextIdx += 1;
  //             count = parseInt(min.substr(idx + 1, nextIdx + 1), 10);
  //           }

  //           if (min[idx] !== "[") {
  //             str += min[idx].repeat(count);
  //           } else {
  //             str += "\n";
  //           }

  //           idx = nextIdx;
  //         }
  //         console.log(str);
  //       } else {
  //         console.log("this easter egg is unavailable in safari...")
  //       }
  //     });
  // }, []);

  return (
    <div className="puzzle-container">
      <Helmet>
        <title>CLOSET</title>
      </Helmet>
      <audio ref={f1} src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/F1.mp3"></audio>
      <audio ref={f2} src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/F2.mp3"></audio>
      { /* stage 0 */ }
      <div className={classnames("puzzle-content", { enter: stage === 0 })}>
        { stage === 0 && <Stage0 name="Janitor's Closet" stage={stage} setStage={setStage} /> }
      </div>

      { /* stage 1 */ }
      <div className={classnames("puzzle-content", { enter: stage === 1 })}>
        <BackstageZoom />
        <div className="static-tiled"></div>
        {/* stage === 1 && <Gizmos /> }

        { stage === 1 && <OnAirSign /> */}

        { stage === 1 &&
          <>
            <img className="wide" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/freddy_cart.png" />
            <button className="clipboard-glitch" title="Clipboard" onClick={() => setStage(3)}>
              <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/clipboard-glitch.gif" />
            </button>
            <button className="tape-glitch" title="Tape Recorder" onClick={() => setStage(2)}>
              <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/taperecorder-glitchs.gif" />
            </button>
          </>
        }
      </div>

      { /* stage 2 */ }
      <div className={classnames("puzzle-content", { enter: stage === 2 })}>
        <BackstageZoom />
        <div className="static-tiled"></div>
        { stage === 2 &&
          <>
            <img className="wide" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/freddy_cart.png" />
            <TapeRecorder f1={f1} f2={f2} />
          </>
        }
      </div>

      { /* stage 3 */ }
      <div className={classnames("puzzle-content", { enter: stage === 3 })}>
        <BackstageZoom />
        <div className="static-tiled"></div>
        { stage === 3 &&
          <>
            <img className="wide" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/janitors_closet/freddy_cart.png" />
            <Clipboard />
          </>
        }
      </div>

      <BackButton stage={stage} setStage={setStage} />

      <FinaleNotice />
    </div>
  );
};
