import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import log from "loglevel";
import { useColyseusClient } from "./ColyseusClientProvider";
import { useShow } from "./ShowProvider";
import { useUser } from "./UserProvider";

const ChatContext = createContext(null);

function ChatProvider (props) {
  const client = useColyseusClient();
  const { show } = useShow();
  const user = useUser();
  
  const [loading, setLoading] = useState(true);
  const [chatRoom, setChatRoom] = useState(null);
  // const [lobby, setLobby] = useState(null);
  // const [allRooms, setAllRooms] = useState([]);

  const [count, setCount] = useState(0);
  const [messages, setMessages] = useState([]);
  const [attendees, setAttendees] = useState({});
  const [attendeeList, setAttendeeList] = useState([]);

  const [dms, setDms] = useState({});
  const [dmMessages, setDmMessages] = useState({});
  const [atNotifications, setAtNotifications] = useState([]);
  const [dmNotifications, setDmNotifications] = useState([]);

  //
  // listen for notifications on user
  //
  const listenForNotifications = useCallback(
    (instance) => {
      instance.atNotifications.onAdd = (i, k) => {
        setAtNotifications(s => ([ ...s, i ]));
      }
      instance.dmNotifications.onAdd = (i, k) => {
        setDmNotifications(s => ([ ...s, i ]));
      };
      instance.atNotifications.onRemove = (i, k) => {
        setAtNotifications(s => {
          const newList = s.slice();
          newList.splice(k, 1);
          return newList;
        });
      }
      instance.dmNotifications.onRemove = (i, k) => {
        setDmNotifications(s => {
          const newList = s.slice();
          newList.splice(k, 1);
          return newList;
        });
      };
    }, []
  );

  //
  // new chat message
  //
  // const onAddChatMessage = useCallback(
  //   (instance, key) => {
  //     setMessages(s => [...s, instance]);
  //   }, []
  // );

  //
  // chat message removed
  //
  // const onRemoveChatMessage = useCallback(
  //   (instance, key) => {
  //     log.debug("ChatProvider: onRemoveChatMessage", instance.message, key);
  //     setMessages(s => {
  //       const newList = s.slice();
  //       const idx = s.findIndex((m) => m.message === instance.message && m.createdAt === instance.createdAt);
  //       log.debug("ChatProvider: onRemoveChatMessage", key, idx);
  //       newList.splice(idx, 1);
  //       return newList;
  //     });
  //   }, []
  // );

  //
  // new attendee
  //
  const onAddAttendee = useCallback(
    (instance, key) => {
      if (instance.id === user.id) {
        listenForNotifications(instance);
      }
      // setAttendees(s => ({...s, [key]: instance }));
    }, [listenForNotifications, user]
  );

  //
  // attendee left
  //
  // const onRemoveAttendee = useCallback(
  //   (instance, key) => {
  //     // todo maybe not?
  //     setAttendees(s => {
  //       const newState = {...s};
  //       delete newState[key];
  //       return newState;
  //     });
  //   }, []
  // );

  //
  // send a chat message
  //
  const sendChat = useCallback(
    (message) => {
      chatRoom.send("chat", message);
    }, [chatRoom]
  );

  //
  // send a direct message
  //
  const sendDM = useCallback(
    (message, recipientId) => {
      chatRoom.send("dm", recipientId);
      dms[recipientId].send("chat", message);
    }, [chatRoom, dms]
  );

  //
  // get a direct message room
  //
  const getDMRoom = useCallback(
    async (recipientId) => {
      if (dms[recipientId]) return dms[recipientId];
      // console.time("ChatProvider: getDMRoom");

      const participants = [ recipientId, user.id ].sort().join(",");
      try {
        log.debug("ChatProvider:", "getDMRoom", "Check for existing DM room or create one", participants);
        const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/dms/${show._id}/${participants}`);
        if (rawResponse.status === 200) {
          const { roomId } = await rawResponse.json();
          log.debug("ChatProvider:", "getDMRoom", "DM room", roomId);
          const newDmRoom = await client.joinById(roomId, { accessToken: user.token });
          log.debug("ChatProvider:", "getDMRoom", "Joined DM room");
          newDmRoom.state.messages.onAdd = (instance, key) => {
            setDmMessages(s => ({
              ...s,
              [recipientId]: newDmRoom.state.messages.toJSON()
            }))
          };
          setDms({
            ...dms,
            [recipientId]: newDmRoom
          });
          return newDmRoom;
        } else {
          log.warn("ChatProvider:", "getDMRoom", "Failed to find or create DM Room");
        }
      } catch (err) {
        log.error("ChatProvider:", "getDMRoom", "Failed to find or create DM Room", err);
      } finally {
        // console.timeEnd("ChatProvider: getDMRoom");
      }
    }, [client, dms, show, user]
  );

  //
  // join chat room
  //
  useEffect(() => {
    async function joinChatRoom() {
      if (chatRoom) return;
      log.info("ChatProvider:", "join");
      try {
        const newRoom = await client.joinOrCreate("chat_room", { accessToken: user.token, show_id: show._id });

        newRoom.onStateChange/*.once*/((state) => {
          // log.debug("ChatProvider: first state", state);
          setMessages(state.messages.toJSON());
          // state.messages.onAdd = onAddChatMessage;
          // state.messages.onRemove = onRemoveChatMessage;
          setAttendees(state.attendees.toJSON());
          // state.attendees.onRemove = onRemoveAttendee;
        });

        newRoom.state.attendees.onAdd = onAddAttendee;


        // setMessages(lobby.state.messages.toJSON());
        // setAttendees(lobby.state.attendees.toJSON());

        // lobby.state.messages.onAdd = onAddChatMessage;
        // lobby.state.messages.onRemove = onRemoveChatMessage;
        // lobby.state.attendees.onAdd = onAddAttendee;
        // lobby.state.attendees.onRemove = onRemoveAttendee;

        // newRoom.state.attendees.onAdd = (instance, key) => {
        //   if (instance.id === user.id) {
        //     listenForNotifications(instance);
        //   }
        // }

        newRoom.onLeave((code) => {
          log.info("ChatProvider:", "onLeave", code);
          newRoom.removeAllListeners();
          setChatRoom(null);
        });

        const rooms = await client.getAvailableRooms();
        log.info("ChatProvider:", "joinChatRoom", rooms);

        setChatRoom(newRoom);
      } catch (err) {
        log.error("ChatProvider:", "joinChatRoom", err);
      } finally {
        setLoading(false);
      }
    }

    joinChatRoom();

    return () => {
      if (chatRoom) {
        chatRoom.removeAllListeners();
      }
    };
  }, [client, chatRoom, user, show._id, listenForNotifications, onAddAttendee ]);

  //
  // leave room
  //
  useEffect(() => {
    function leaveRoom() {
      if (chatRoom) {
        log.info("ChatProvider:", "leaveRoom");
        chatRoom.onStateChange.clear();
        chatRoom.leave();
        setChatRoom(null);
      }
    };

    return () => {
      if (chatRoom) {
        log.debug("ChatProvider:", "Unmount");
        leaveRoom();
        // if (newRoomTimeout.current) clearTimeout(newRoomTimeout.current);
      }
    };
  }, [chatRoom]);

  //
  // 
  //
  // useEffect(() => {
  //   if (lobby) {
  //     log.debug("ChatProvider:", "Listening to lobby state");
  //     setMessages(lobby.state.messages.toJSON());
  //     setAttendees(lobby.state.attendees.toJSON());

  //     lobby.state.messages.onAdd = onAddChatMessage;
  //     lobby.state.messages.onRemove = onRemoveChatMessage;
  //     lobby.state.attendees.onAdd = onAddAttendee;
  //     lobby.state.attendees.onRemove = onRemoveAttendee;
  //   }
  // }, [user, lobby, onAddChatMessage, onAddAttendee, onRemoveAttendee, onRemoveChatMessage]);

  useEffect(() => {
    const list = Object.values(attendees).filter((a) => a.id !== user.id);
    list.sort((a, b) => {
      const aName = a.displayName.toLowerCase();
      const bName = b.displayName.toLowerCase();
      const dmKeys = Object.keys(dms);
      if (/eschaton/.test(aName)) return -1;
      if (/eschaton/.test(bName)) return 1;
      if (dmNotifications.indexOf(a.id) > -1) return -1;
      if (dmNotifications.indexOf(b.id) > -1) return 1;
      if (dmKeys.indexOf(a.id) > -1) return -1;
      if (dmKeys.indexOf(b.id) > -1) return 1;
      if (aName < bName) return -1;
      if (bName < aName) return 1;
      return 0;
    });
    setAttendeeList(list);
  }, [user, attendees, dms, dmNotifications]);


  useEffect(() => {
    setCount(Object.keys(attendees).length);
  }, [attendees]);

  useEffect(() => {
    log.debug("ChatProvider:", "messages updated", messages);
  }, [messages]);

  useEffect(() => {
    log.debug("ChatProvider:", "attendees updated", attendees);
  }, [attendees]);

  useEffect(() => {
    log.debug("ChatProvider:", "@s updated", atNotifications);
  }, [atNotifications]);

  useEffect(() => {
    log.debug("ChatProvider:", "DMs updated", dmNotifications);
  }, [dmNotifications]);

  if (loading) {
    return <p className="chat-loading">Loading...</p>;
  }

  return (
    <ChatContext.Provider value={{
      chatRoom,
      // me: { atNotifications, dmNotifications, setAtNotifications, setDmNotifications },
      sendChat,
      sendDM,
      getDMRoom,
      chatState: {
        count,
        messages,
        dmMessages,
        attendees: attendeeList,
        atNotifications,
        dmNotifications
      },
      setAtNotifications,
      setDmNotifications
    }} {...props} />
  );
}

const useChat = () => useContext(ChatContext);

export { ChatProvider, useChat };
