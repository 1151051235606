import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import classnames from "classnames";
import FinaleNotice from "../FinaleNotice";
import Stage0 from "./Stage0";
import BackstageWide from "./BackstageWide";
import BackstageZoom from "./BackstageZoom";
import "../../styles/puzzles.scss";
import "../../styles/green.scss";

function Journal({ onComplete }) {
  const [page, setPage] = useState(0);

  const nextPage = () => setPage(page + 1);
  const prevPage = () => setPage(page - 1);

  useEffect(() => {
    if (page > 15) onComplete();
  }, [page]);

  return (
    <div className={classnames("journal-overlay", { active: page > -1, 'book-open': page > 0 })}>
      <div id="journal">
        { page === 0 &&
          <>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/closed-book.png" alt="Mary's journal closed" />
            <button className="book-band-button" title="Open Mary's Journal" onClick={nextPage}>
              <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/book-band-glitch.gif" alt="Glitchy book band" />
            </button>
          </>
        }
        { page > 0 &&
          <React.Fragment>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/open-book.png" alt="Mary's journal open" />
            <button className="journal-back-page" title="Previous Page" onClick={prevPage}>
              { page === 2 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "70%", left: "60%", transform: "translate(-50%, -50%) scale(0.8)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/21.png" />
              }
              { page === 3 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "75%", left: "65%", transform: "translate(-50%, -50%) scale(0.5)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/9.png" />
              }
              { page === 4 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "40%", left: "60%", transform: "translate(-50%, -50%) scale(0.5)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/1.png" />
              }
              { page === 5 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "50%", left: "60%", transform: "translate(-50%, -50%) scale(0.8)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/16.png" />
              }
              { page === 7 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "10%", left: "80%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/11.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/10.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "55%", left: "60%", transform: "translate(-50%, -50%) rotate(-30deg) scale(0.6)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/8.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "80%", left: "70%", transform: "translate(-50%, -50%) rotate(10deg) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/5.png" />
                </>
              }
              { page === 8 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "50%", left: "60%", transform: "translate(-50%, -50%) scale(0.8)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/17.png" />
              }
              { page === 10 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "50%", left: "60%", transform: "translate(-50%, -50%) scale(0.6)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/4.png" />
              }
              { page === 13 &&
                <img
                  alt=""
                  style={{ position: "absolute", top: "50%", left: "60%", transform: "translate(-50%, -50%) scale(0.8)"}}
                  src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/20.png" />
              }
            </button>
            <button className="journal-page" title="Next Page" onClick={nextPage}>
              <p className="handwriting-mary page-no">{page}</p>
              { page === 1 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%) scale(0.7)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/14.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "75%", left: "75%", transform: "translate(-50%, -50%) scale(0.25)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/7.png" />
                  <p className="handwriting-mary">This notebook belongs to Mary Hail.</p>
                </>
              }
              { page === 2 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "56%", left: "49%", transform: "translate(-50%, -50%) scale(0.45)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/22.png" />
                  <p className="handwriting-mary">Met so many nice people today!</p>
                  <p className="handwriting-mary">First show is next week!</p>
                </>
              }
              { page === 3 &&
                <p className="handwriting-mary">It's not exactly how I thought it was going to be. It's not so pretty. Not like it seems. I had to trade a lot to get here... I just thought the grass would be greener. At least I made a friend. A real friend. Her name is Sheila. Everything is easier when you're not alone.</p>
              }
              { page === 4 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "87%", left: "70%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/15.png" />
                  <p className="handwriting-mary">I can’t believe Orin got to do the finale twice in a row. I’d be jealous if he wasn’t so cute. Okay maybe I’m still a little jealous. Hard not to be.</p>
                  <p className="handwriting-sheila">Yeah, Management LOVES him. It’s very annoying. I wouldn’t be surprised if he was on the Wall of Stars by the end of the month.</p>
                  <p className="handwriting-mary">Not a chance. Willa told me there hasn’t been anyone new on the Wall of Stars in years.</p>
                </>
              }
              { page === 5 &&
                <p className="handwriting-mary">Sheila got redlisted today. It was her fourth time. I hope she can turn it around, she still has time. I just don’t want them to fire my only friend in Eschaton.</p>
              }
              { page === 6 &&
                <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/door-drawing.png" alt="An empty doorway" />
              }
              { page === 7 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "80%", left: "25%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/12.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "80%", left: "70%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/13.png" />
                  <p className="handwriting-mary">To do list:</p>
                  <ul className="handwriting-mary">
                    <li>- practice new song, need to work on the bridge</li>
                    <li>- new fake eyelashes</li>
                    <li>- wash blue crop top</li>
                    <li>- meet with Sheila in the green room</li>
                  </ul>
                </>
              }
              { page === 8 &&
                <p className="handwriting-mary">Sheila is gone. I know she’ll find another job. Find somewhere else to perform. She has to. Just because she couldn’t make it here doesn’t mean there isn’t another place for her.</p>
              }
              { page === 9 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "80%", left: "60%", transform: "translate(-50%, -50%) scale(0.6)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/18.png" />
                  <img
                    alt=""
                    style={{ position: "absolute", top: "90%", left: "60%", transform: "translate(-50%, -50%) scale(0.25)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/6.png" />
                  <p className="handwriting-mary">I got my first finale today!!! But it’s bittersweet. Sheila isn’t here to see it. And I’ve still got countless more to go before...you know, the dream! Wall of Stars, here I come.</p>
                </>
              }
              { page === 10 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "75%", left: "60%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/19.png" />
                  <p className="handwriting-mary">Four in a row!! Can you believe it? I’ve done four finales in a row. I’m exhausted, but I have to keep going. All the hard work will pay off! I owe it to the audience. No matter how hard it is.</p>
                </>
              }
              { page === 11 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "25%", left: "50%", transform: "translate(-50%, -50%) scale(0.3)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/3.png" />
                  <p className="handwriting-mary">Management called! They told me I’m close! I’m so close to being the first person in years to get on the Wall of Stars. I’m shocked. It doesn’t feel real at all.</p>
                </>
              }
              { page === 12 &&
                <>
                  <img
                    alt=""
                    style={{ position: "absolute", top: "65%", left: "70%", transform: "translate(-50%, -50%) scale(0.4)"}}
                    src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/journal_doodles/2.png" />
                  <p className="handwriting-mary">I’ve done three more finales. Just playing the waiting game. I’m dying. Feels like I can’t possibly perform another show. Might need a little something to get me through tonight.</p>
                </>
              }
              { page === 13 &&
                <p className="handwriting-mary">It’s finally happening!! Everything I’ve been working for. Tomorrow I get my headshot on the Wall of Stars and the rat race will finally be over. My dreams will finally come true.</p>
              }
              { page === 14 &&
                <React.Fragment>
                  <p className="handwriting-mary">
                    IM SO ANGRY. I can’t even see straight I'm so mad. They called me in after telling me I was going to be on the Wall of Stars and started spewing all this nonsense about “next steps” and how I have "even more to achieve." I finally made it and this is what I get. It’s all a sham!! There is no end to it! I have to do something. I have to expose them for what they are. 
                  </p>
                  <p className="handwriting-mary">
                    But the best way to do that is to pretend...
                  </p>
                  <p className="handwriting-mary">
                    If I want to uncover the truth, I have to play the game. Act like everything is normal. For now I need to relax and record for my audience. It's the best way to clear my head. More later...I'm not going to let them get away with this.
                  </p>
                </React.Fragment>
              }
              { page === 15 &&
                <p className="handwriting-mary"></p>
              }
            </button>
          </React.Fragment>
        }
      </div>
    </div>
  );
}

export default function GreenRoom() {
  const [stage, setStage] = useState(0);
  const [read, setRead] = useState(false);
  const [slide, setSlide] = useState(false);
  const [showPoster, setShowPoster] = useState(false);
  const [showJournal, setShowJournal] = useState(false);
  const droneRef = useRef();

  function back() {
    if (showJournal) {
      setShowJournal(false);
    } else if (showPoster) {
      setShowPoster(false);
      setSlide(false);
    } else if (slide) {
      setSlide(false);
    } else {
      setStage(stage - 1);
    }
  }

  function clickJournal() {
    setSlide(false);
    setShowPoster(false);
    setShowJournal(true);
  }

  function finishJournal() {
    setRead(true);
    setShowJournal(false);
  }

  function clickPoster() {
    if (read) {
      if (!slide) {
        setSlide(true);
      } else {
        setShowPoster(true);
      }
    }
  }

  useEffect(() => {
    if (stage !== 2) {
      setShowJournal(false);
      setSlide(false);
      setShowPoster(false);
    }
    if (stage !== 0) {
      if (droneRef.current) droneRef.current.muted = true;
    }
  }, [stage]);

  return (
    <div className="puzzle-container">
      <Helmet>
        <title>GREEN</title>
      </Helmet>

      <div className={classnames("puzzle-content", { enter: stage === 0 })}>
        { stage === 0 && <Stage0 name="Green Room" stage={stage} setStage={setStage} /> }
      </div>

      <div className={classnames("puzzle-content green-room-content", { enter: stage === 1 })}>
        <BackstageWide />
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-static.gif" className="locker-static" alt=""/>
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/wideview.png" className="wide-view" alt="Wide view of green room with a locker, water cooler, armchair, and lamp" />
        
        <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/water-anim-sm.gif" className="water" alt="Water dripping from a cooler" />

        <button
          title="Locker Door"
          className="locker-door"
          onClick={() => setStage(2)}
        >
          <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-door.png" alt="Locker Door" />
          <img className="door-glitch" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-door-glitch.gif" alt="Glitchy Locker Door" />
        </button>
        { stage === 1 &&
          <div className="back-buttons">
            <Link className="btn-back continue" data-text="HOME" to="/home">HOME</Link>
          </div>
        }
      </div>

      <div className={classnames("puzzle-content locker-close-content", { enter: stage === 2 })}>
        <BackstageZoom />
        <div className={classnames("locker-wrapper", { "blur": showJournal || showPoster, "slide": slide })}>
          <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-static.gif" className="locker-static" alt="" />
          <img
            alt="An open locker. The top shelf has a water bottle with the label 'Sheila was here' and three tattered books. The lower shelf has several coats and a feather boa."
            className="locker-view"
            src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker.png"
          />
          <button className="book-button" title="Mary's Journal" onClick={clickJournal} disabled={stage !== 2}>
            <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/locker-book-glitch_loop.gif" className="book-glitch" alt="Glitchy book" />
          </button>

          <button className="poster-button" disabled={stage !== 2 || !read} onClick={clickPoster} title="Fortune Teller Poster">
            <div className={classnames("poster-fill", { glitch: read })}></div>
            <img className="email-glitch" src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/poster-email-glitch.gif" alt="" />
          </button>
        </div>

        { showPoster && <div className="poster-overlay">
            <div className="mme-saluer-poster show">
              <p className="description">
                The universe is vast. I am here to guide you. To receive your fortune, the subject should be: "Fortune for [Your Full Name]". mmesaluer@gmail.com
              </p>
              <img src="https://cdn.chorusproductions.com/eschaton-v2/puzzles/green_room/mme_saluer_poster.png" alt="Poster" />
            </div>
          </div>
        }

        { showJournal &&
          <Journal onComplete={finishJournal} />
        }

        { stage === 2 &&
          <div className="back-buttons">
            <Link className="btn-back continue" to="#" onClick={back}>BACK</Link>
          </div>
        }
      </div>
      <FinaleNotice />
    </div>
  );

};
