import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Typing from "./React-Typing-Animation";
import classnames from "classnames";
import log from "loglevel";
import { useAuth } from "../context/AuthProvider";
import { useUser } from "../context/UserProvider";
import { useShow, SHOW_STATES } from "../context/ShowProvider";
import SmokeContainer from "./SmokeContainer";
import "../styles/login.scss";
import "../styles/modal.scss";

const lines = [
  {
    caption : "Enter your password: ",
    input: "code",
    errors: {
      401: { caption: "Incorrect password. Please contact [boxoffice@chorusproductions.com] if you need help.", link: "mailto:boxoffice@chorusproductions.com" },
      403: { caption: "Too many incorrect code attempts. Please contact [boxoffice@chorusproductions.com] if you need help.", link: "mailto:boxoffice@chorusproductions.com" },
      404: { caption: "The show isn't open. Please come back later." },
      500: { caption: "Oh dear, something has gone wrong. Please contact [boxoffice@chorusproductions.com] if you need help.", link: "mailto:boxoffice@chorusproductions.com" }
    }
  },
  {
    caption : "Is this your first time in Eschaton? [y or n]? ",
    input: "first_time"
  },
  {
    caption : "Oh my, it’s your first time. Lucky us. ",
    next: 4
  },
  {
    caption : "Glad to see you're back for more. ",
  },
  {
    caption : "What name would you like to go by tonight? ",
    input: "name",
    errors: {
      401: { caption: "Oops, looks like your evil twin has already used that name. Choose something else." },
      404: { caption: "The show isn't open. Please come back later." },
      500: { caption: "Oh dear, something has gone wrong. Please contact [boxoffice@chorusproductions.com] if you need help.", link: "mailto:boxoffice@chorusproductions.com" }
    }
  },
  {
    caption: "I like that you’re easy, %. Now dim the lights, pour yourself a drink, and enjoy your evening in Eschaton...",
    end: true
  },
  {
    caption : "Great choice, %. Keep this pretty little browser window open the whole show. It’ll launch you into all kinds of fantasies, mostly of the Zoom variety.",
  },
  {
    caption : "Please review our <House Rules> to ensure we all get along tonight.",
    button: () => {},
  },
  {
    caption : "Now..."
  },
  {
    caption : "R u ready, %? Dim your lights, pour yourself a drink, and enjoy your evening…",
    end: true
  }
];

function Login() {
  const { state } = useShow();
  const user = useUser();
  const { login, checkCode, checkName } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loggedInAlready, setLoggedInAlready] = useState(false);
  const [complete, setComplete] = useState(false);
  const [pastLines, setPastLines] = useState([]);
  const [lineIdx, setLineIdx] = useState(0);
  const [expectingInput, setExpectingInput] = useState("");
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  function onBlur(evt) {
    evt.target.focus();
  }

  async function onSubmit(evt) {
    evt.preventDefault();
    if (loading) return;
    setLoading(true);
    const input = evt.target.querySelector("input");
    const step = input.name;
    const value = input.value;
    let nextIdx = lineIdx + 1;
    log.info("Login:", "onSubmit", step, value);
    try {
      let status;
      if (step === "code") {
        status = await checkCode(value);
      } else if (step === "name") {
        status = await checkName(value);
        if (status === 200) {
          status = await login(value, form.code);
        }
        const firstTime = form.first_time;
        if (firstTime === "no" || firstTime === "n") {
          nextIdx = 5;
        } else {
          nextIdx = 6;
        }
      } else if (step === "first_time") {
        status = 200;
        const answer = value.toLowerCase();
        if (answer === "no" || answer === "n") {
          nextIdx = 3;
        }
      } else {
        status = 200;
      }
      setForm({
        ...form,
        [step]: value
      });
      if (error) {
        setPastLines([
          ...pastLines,
          (<React.Fragment key={`error-${pastLines.length}`}>
            {lines[lineIdx].errors && <p className="error"><span>{getCaption(lines[lineIdx].errors[error])}</span></p>}
            <p><span>Try again: {value}</span></p>
          </React.Fragment>)
        ]);
      } else {
        setPastLines([
          ...pastLines,
          (<p key={`past-${pastLines.length}`}><span>{lines[lineIdx].caption} {value}</span></p>)
        ]);
      }
      
      if (status === 200) {
        setError(null);
        setLineIdx(nextIdx);
        setExpectingInput("");
      } else {
        setError(status);
        log.error("Login:", "onSubmit", status);
      }
    } catch (err) {
      log.error("Login:", "onSubmit", err);
    } finally {
      setLoading(false);
    }
  }

  function lineComplete() {
    if (lineIdx + 1 === lines.length || lines[lineIdx].end) {
      setComplete(true);
    } else if (!lines[lineIdx].input || lines[lineIdx].complete) {
      const caption = getCaption(lines[lineIdx]);
      setTimeout(() => {
        setPastLines([
          ...pastLines,
          (<p key={`past-${pastLines.length}`}><span>{caption}</span></p>)
        ]);
        setLineIdx(lines[lineIdx].next || lineIdx + 1);
      }, 750);
    } else if (lines[lineIdx].input) {
      setExpectingInput(lines[lineIdx].input);
    }
  }

  function getCaption(line) {
    let caption = line.caption;

    // replace with name
    if (caption.indexOf('%') >= 0) {
      const split = caption.split('%');
      return (
        <React.Fragment>
          {split.map((line, idx) => {
            if (idx === split.length - 1) return line;
            return (
              <>
                {line}
                <span style={{ textTransform: "none" }}>{form.name}</span>
              </>
            );
          })}
        </React.Fragment>
      );
      // caption = caption.replace(/%/g, form.name);
    }

    if (!line.link && !line.button) return caption;

    // insert links
    if (line.link) {
      const linkStartIdx = caption.indexOf('[') + 1;
      const linkEndIdx = caption.indexOf(']');
      const prefix = caption.substring(0, linkStartIdx - 1);
      const linkText = caption.substring(linkStartIdx, linkEndIdx);
      let suffix = '';
      if (linkEndIdx < caption.length - 1) {
        suffix = caption.substring(linkEndIdx + 1);
      }
      return (
        <React.Fragment>
          {prefix.length > 0 ? prefix : <span></span>}
          <a href={line.link} target="_blank" rel="noopener noreferrer" className="link">{linkText}</a>
          {suffix.length > 0 ? suffix : <span></span>}
        </React.Fragment>
      );
    }

    // insert buttons
    if (line.button) {
      const buttonStartIdx = caption.indexOf('<') + 1;
      const buttonEndIdx = caption.indexOf('>');
      const prefix = caption.substring(0, buttonStartIdx - 1);
      const buttonText = caption.substring(buttonStartIdx, buttonEndIdx);
      let suffix = '';
      if (buttonEndIdx < caption.length - 1) {
        suffix = caption.substring(buttonEndIdx + 1);
      }
      return (
        <React.Fragment>
          {prefix.length > 0 ? prefix : <span></span>}
          <button onClick={() => setModalOpen(true)} title={buttonText} className="link">{buttonText}</button>
          {suffix.length > 0 ? suffix : <span></span>}
        </React.Fragment>
      );
    }
  }

  useEffect(() => {
    if (user) {
      setLoggedInAlready(true);
    }
  }, []); // NOTE: intentional - only want this effect to run when the component is first mounted

  if (state === SHOW_STATES.CLOSED) {
    log.info("Login", "Show closed, redirecting to entrance.");
    return (<Redirect to="/" />);
  }

  if (loggedInAlready) {
    log.debug("Login:", "Already logged in, should redirect.");
    return (<Redirect to="/lobby" />);
  }

  return (
    <SmokeContainer>
      <Helmet>
        <title>Enter Eschaton</title>
      </Helmet>
      <audio
        src="https://cdn.chorusproductions.com/eschaton-v2/typing/ei_drone.mp3"
        preload="auto"
        autoPlay
        loop
      ></audio>
      <div className="typewriter-text">
        <div className="console">
          {pastLines}

          { error &&
            <Typing speed={40} className="typing" key={pastLines.length}>
              {lines[lineIdx].errors && <p className="error"><span>{getCaption(lines[lineIdx].errors[error])}</span></p>}
              <Typing.Delay ms={500} />
              <form onSubmit={onSubmit}>
                <label htmlFor={expectingInput}>
                  <span>Try again:</span>
                  <input type="text" name={expectingInput} autoFocus autoComplete="off" disabled={loading} onBlur={onBlur} />
                </label>
              </form>
            </Typing>
          }

          { !error && expectingInput &&
            <form onSubmit={onSubmit} key={pastLines.length}>
              <label htmlFor={expectingInput}>
                <span>{getCaption(lines[lineIdx])}</span>
                <input type="text" name={expectingInput} autoFocus autoComplete="off" disabled={loading} onBlur={onBlur} maxLength={expectingInput === "name" ? 24 : 50} />
              </label>
            </form>
          }

          { !error && !expectingInput && 
            <Typing onFinishedTyping={lineComplete} speed={40} className="typing" key={pastLines.length}>
              {getCaption(lines[lineIdx])}
            </Typing>
          }
        </div>
      </div>

      <div id="rules" className={classnames("modal", { visible: modalOpen })}>
        <div className="inner full">
          <button onClick={() => setModalOpen(false)} title="Close Eschaton Rules" className="close">X</button>
          <h2>HOUSE RULES</h2>
          <ul>
            <li>You must be 18 or older to attend Eschaton. No exceptions. </li>
            <li>No photography in Eschaton.</li>
            <li>Hateful remarks, racism, sexism, ageism, or other bigotry or harassment will not be tolerated in Eschaton. </li>
            <li>Do not solicit personal information such as addresses from  our performers. </li>
            <li>We reserve the right to remove anyone from Eschaton for inappropriate behavior at any time without a refund. Please respect our performers and each other.</li>
          </ul>
        </div>
      </div>

      <div id="buttons" className={classnames("btn-container", { "on": complete })}>
        <Link className="btn-forward continue" data-text="CONTNUE" to="/lobby">CONTINUE</Link>
      </div>
    </SmokeContainer>
  );
}

export default Login;
