import * as THREE from "three";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';

let camera, scene, renderer, composer;
let /*object, light,*/ font, material;
let onEnd;

let glitchPass;
let idx = 0;
let tid;
let end = false;

const messages = [
  "System Error\n******************************************\nIntruder alert.\n",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.\nThis is your final warning.",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.\nThis is your final warning.\nEjection in 3,",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.\nThis is your final warning.\nEjection in 3, 2,",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.\nThis is your final warning.\nEjection in 3, 2, 1...",
  "System Error\n******************************************\nIntruder alert.\nIntruder alert.\nDo not touch the Audience Spark.\nReplace bulb immediately or risk ejection.\nThis is your final warning.\nEjection in 3, 2, 1..._"
];

function generateMessage(idx) {
  const message = messages[idx];

  const shapes = font.generateShapes( message, 25 );

  const geometry = new THREE.ShapeGeometry( shapes );

  geometry.computeBoundingBox();

  const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );

  geometry.translate( xMid, 100, 0 );

  const text = new THREE.Mesh( geometry, material );
  text.position.z = - 150;
  scene.add( text );
}

export function init(endFn) {
  onEnd = endFn;

  renderer = new THREE.WebGLRenderer();
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );
  document.body.appendChild( renderer.domElement );

  //

  camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 1, 1000 );
  camera.position.z = 400;

  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0x0000ff );

  const color = 0xffffff;

  material = new THREE.MeshBasicMaterial( {
    color: color,
    side: THREE.DoubleSide
  } );

  const loader = new THREE.FontLoader();
  loader.load( 'https://cdn.chorusproductions.com/eschaton-v2/puzzles/forest/dos.typeface.json', function ( loadedFont ) {

    font = loadedFont;

    generateMessage(0);

    animate();

  } ); //end load function

  scene.add( new THREE.AmbientLight( 0x222222 ) );

  // postprocessing

  composer = new EffectComposer( renderer );
  composer.addPass( new RenderPass( scene, camera ) );

  glitchPass = new GlitchPass();
  composer.addPass( glitchPass );

  window.addEventListener( 'resize', onWindowResize );
}

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
  composer.setSize( window.innerWidth, window.innerHeight );


}

function animate(del) {

  if (idx === messages.length) end = true;
  if (!end) requestAnimationFrame( animate );

  if (!tid && idx < messages.length) {
    tid = setTimeout(() => {
      generateMessage(idx++);
      if (idx === 7) glitchPass.goWild = true;
      tid = null;
    }, 1000);
  }

  composer.render();

  // if (end) {
  //   tunnel.parentNode.className += ' on';
  //   tunnel.play();
  // }
  if (end) {
    onEnd();
    renderer.domElement.remove();
    renderer.dispose();
  }

}