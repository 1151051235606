import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import log from "loglevel";
import { Helmet } from "react-helmet-async";
import { useSpring, animated } from 'react-spring';
// import classnames from "classnames";
import { Stream } from "@cloudflare/stream-react";
import { useColyseusClient } from "../context/ColyseusClientProvider";
import { useLobby } from "../context/LobbyProvider";
import { useUser } from "../context/UserProvider";
// import { useShow, SHOW_STATES } from "../context/ShowProvider";
import NotFound from "./404";
import NotOpen from "./NotOpen";
import FinaleNotice from "./FinaleNotice";
import "../styles/green.scss";
import "../styles/fame.scss";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.tz.setDefault("America/New_York");

export default function FameRoom() {
  const { id } = useParams();
  const client = useColyseusClient();
  const user = useUser();
  const { allRooms, showState } = useLobby();
  const [room, setRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [adoration, setAdoration] = useState("");
  const [glitch, setGlitch] = useState(false);

  const roomListing = allRooms.find((r) => r.roomId === id);
  const styles = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  function handleSubmit(e) {
    e.preventDefault();
    if (room) {
      adoration.trim();
      if (!adoration) return;
      room.send("chat", adoration);
      setAdoration("");
    }
  }

  function onTextAreaBlur(e) {
    e.target.focus();
  }

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  function videoEnded(evt) {
    if (roomListing.metadata.key === "MARY") {
      setGlitch(true);
    }
  }

  useEffect(() => {
    async function joinRoom(id) {
      try {
        log.debug("Fame", "join room", id);
        const room = await client.joinById(id, { accessToken: user.token });
        room.onStateChange((updatedState) => {
          setMessages(updatedState.messages.toJSON());
        });
        setRoom(room);
      } catch (err) {
        log.error("Fame", "joinRoom", id, err);
      }
    }

    if (id && !room) {
      joinRoom(id);
    }

    return () => {
      if (room) {
        log.debug("Fame", "leave room", roomListing.metadata.key);
        room.onStateChange.clear();
        room.leave();
      }
    }
  }, [id, room]);

  if (showState === "Closed" || showState === "Ended") {
    log.info("Fame", "Show closed, redirecting to entrance.");
    return <Redirect to="/" />;
  }

  if (showState === "Preshow") {
    log.info("Fame", "Show not open yet.");
    return <NotOpen />;
  }

   if (showState === "Finale" || showState === "Afterparty") {
    log.info("Fame", "Show over, redirecting to main navigation.");
    return <Redirect to="/home" />;
  }
  
  if (!roomListing) {
    return <NotFound />;
  }

  return (
    <animated.div style={styles}>
    <div className="w-full overflow-hidden">
      <Helmet>
        <title>{roomListing.metadata.key}</title>
      </Helmet>
      <div>
        <div className="">
          <div className="screen-container">
            <div className="fame-video">
              <Stream
                controls
                src={roomListing.metadata.fame_video_id}
                onEnded={videoEnded}
                onError={(err) => log.error("FameRoom:", err)}
                onStalled={() => log.warn("FameRoom:", "Video playback stalled.")}
              />
            </div>
            <img
              alt=""
              className="fame-frame big-screen"
              src="https://cdn.chorusproductions.com/eschaton-v2/fame/fame_video_frame.png"
            />
          </div>
          <div className="fame-adoration">
            <div className="fame-chat">
              <div className="adoration-messages">
                <ul>
                  {messages.map((msg) => 
                    glitch
                    ? <li key={msg.createdAt}>
                        <p>
                          blue l1ght doesn’t last forever? if yes, come, c0m3. key is GREEN.
                        </p>
                        <p>
                          *UNKNOWN- {dayjs(msg.createdAt).format("h:mmA")}*
                        </p>
                      </li>
                    : <li key={msg.createdAt}>
                        <p>
                          {msg.message}
                        </p>
                        <p>
                          *{msg.displayName}- {dayjs(msg.createdAt).format("h:mmA")}*
                        </p>
                      </li>
                  )}
                </ul>
              </div>
              <form onSubmit={handleSubmit}>
                <label htmlFor="adoration">
                  <img src="https://cdn.chorusproductions.com/eschaton-v2/fame/leave-your-love.png" alt="Leave Your Love" />
                  <textarea
                    name="adoration"
                    maxLength="288"
                    rows="2"
                    placeholder="Write message here ;)"
                    autoFocus
                    autoComplete="off"
                    value={adoration}
                    onChange={(evt) => setAdoration(evt.target.value)}
                    onKeyUp={submitOnEnter}
                    onBlur={onTextAreaBlur}
                  />
                </label>
                <input type="submit" title="Send" value="" className="send-love-btn" />
              </form>
            </div>
          </div>
        </div>
        <div id="buttons" className="back-buttons">
          <Link className="btn-back continue" data-text="HOME" to="/home">HOME</Link>
        </div>
      </div>

      <FinaleNotice />

    </div>
    </animated.div>
  );

};
