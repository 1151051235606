import React, { useEffect, useState } from "react";
import classnames from "classnames";
import SmokeContainer from "./SmokeContainer";
import "../styles/loading.css";

const Spinner = () => {
  const [dots, setDots] = useState(2);

  useEffect(() => {
    let intervalId;

    if (!intervalId) {
      intervalId = setInterval(() => {
        setDots((dots + 1) % 4);
      }, 200);
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [dots]);

  return (
    <SmokeContainer>
      <div className="loading-container">
        <div className="loading">
          <span className={classnames({ "opacity-0": dots < 1 })}>.</span>
          <span className={classnames({ "opacity-0": dots < 2 })}>.</span>
          <span className={classnames({ "opacity-0": dots < 3 })}>.</span>
        </div>
      </div>
    </SmokeContainer>
  );
}

export default Spinner;
