import React, { useState, useEffect, useCallback } from "react";
import log from "loglevel";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);
dayjs.tz.setDefault("America/New_York");

const ShowContext = React.createContext();

const SHOW_STATES = {
  "LOADING": "LOADING",
  "CLOSED": "CLOSED",
  "PRESHOW": "PRESHOW",
  "OPEN": "OPEN",
  "FINALE": "FINALE",
  "AFTERPARTY": "AFTERPARTY",
  "ERROR": "ERROR"
};

const ShowProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(null);
  const [state, setState] = useState("LOADING");

  const getMsUntil = useCallback(
    (time) => {
      if (show) {
        const currentTimeInNY = dayjs();
        return dayjs(time).tz(show.timezone).diff(currentTimeInNY);
      }
      return null;
    },
    [show]
  );

  useEffect(() => {
    log.info("ShowProvider:", "Show state change", state);
  }, [state])

  useEffect(() => {
    async function getShow() {
      setLoading(true);
      try {
        const rawResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/shows/next`);
        if (rawResponse.status === 200) {
          const { show } = await rawResponse.json();
          log.debug("ShowProvider:", "Got next show", show._id);
          setShow(show);
        } else {
          log.debug("ShowProvider:", "No next show");
          setShow(null);
        }
        return rawResponse.status;
      } catch (err) {
        log.error("useShow", "getShow", err);
        setShow(null);
      } finally {
        setLoading(false);
      }
    }

    getShow();

    return () => {
      log.debug("useShow", "leave");
      setShow(null);
    };
  }, []);

  useEffect(() => {
    let preshowTimeout, startTimeout, finale_timeout, endTimeout, afterparty_timeout;
    if (!loading) {
      if (show) {
        const msTilPreshow = getMsUntil(show.preshow);
        const msTilStart = getMsUntil(dayjs(show.start).tz(show.timezone).add("2", "minute"));
        const msTilFinale = getMsUntil(show.finale_time);
        const msTilFinaleEnd = getMsUntil(show.finale_end_time);
        // const msTilEnd = getMsUntil(show.end);
        const msTilAfterpartyEnd = getMsUntil(show.afterparty_time);
        log.debug("ShowProvider:", "Preshow opens in", msTilPreshow, "ms");
        log.debug("ShowProvider:", "Show opens in", msTilStart, "ms");
        log.debug("ShowProvider:", "Finale begins in", msTilFinale, "ms");
        log.debug("ShowProvider:", "Finale ends in", msTilFinaleEnd, "ms");
        // log.debug("ShowProvider:", "Show ends in", msTilEnd, "ms");
        log.debug("ShowProvider:", "Afterparty ends in", msTilAfterpartyEnd, "ms");

        if (msTilPreshow > 0) {
          setState(SHOW_STATES.CLOSED);
        } else if (msTilStart > 0) {
          setState(SHOW_STATES.PRESHOW);
        } else if (msTilFinale > 0) {
          setState(SHOW_STATES.OPEN);
        } else if (msTilFinaleEnd > 0) {
          setState(SHOW_STATES.FINALE);
        } else if (show.afterparty && msTilAfterpartyEnd > 0) {
          setState(SHOW_STATES.AFTERPARTY);
        } else {
          setState(SHOW_STATES.CLOSED);
        }

        if (!preshowTimeout && msTilPreshow > 0) {
          preshowTimeout = setTimeout(() => {
            setState(SHOW_STATES.PRESHOW);
          }, msTilPreshow);
        }

        if (!startTimeout && msTilStart > 0) {
          startTimeout = setTimeout(() => {
            setState(SHOW_STATES.OPEN);
          }, msTilStart);
        }

        if (!finale_timeout && msTilFinale > 0) {
          finale_timeout = setTimeout(() => {
            setState(SHOW_STATES.FINALE);
          }, msTilFinale);
        }

        if (!endTimeout && msTilFinaleEnd > 0) {
          endTimeout = setTimeout(() => {
            if (show.afterparty) {
              setState(SHOW_STATES.AFTERPARTY);
            } else {
              setState(SHOW_STATES.CLOSED);
            }
          }, msTilFinaleEnd);
        }

        if (!afterparty_timeout && show.afterparty) {
          afterparty_timeout = setTimeout(() => {
            setState(SHOW_STATES.CLOSED);
          }, Math.max(0, getMsUntil(show.afterparty_time)));
        }
      } else {
        setState(SHOW_STATES.CLOSED);
      }
    }

    return () => {
      if (show) {
        if (finale_timeout) clearTimeout(finale_timeout);
        if (endTimeout) clearTimeout(endTimeout);
        if (startTimeout) clearTimeout(startTimeout);
        if (preshowTimeout) clearTimeout(preshowTimeout);
        if (afterparty_timeout) clearTimeout(afterparty_timeout);
      }
    }
  }, [show, loading, getMsUntil]);

  if (loading) return <p>Loading...</p>;

  return <ShowContext.Provider value={{ show, state, getMsUntil }} {...props} />;
};

const useShow = () => React.useContext(ShowContext);

export { ShowProvider, useShow, SHOW_STATES };
