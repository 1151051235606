import React, { createContext, useState, useContext, useEffect, useCallback } from "react";
import log from "loglevel";
import { useColyseusClient } from "./ColyseusClientProvider";
import { useShow } from "./ShowProvider";
import { useUser } from "./UserProvider";
import Spinner from "../components/Spinner";

const LobbyContext = createContext(null);

function LobbyProvider (props) {
  const client = useColyseusClient();
  const { show } = useShow();
  const user = useUser();
  
  const [loading, setLoading] = useState(true);
  const [lobby, setLobby] = useState(null);
  const [allRooms, setAllRooms] = useState([]);
  const [showState, setShowState] = useState("Loading");

  //
  // rooms received
  //
  const onRooms = useCallback(
    (rooms) => {
      log.debug("LobbyProvider:", "onRooms", rooms);
      setAllRooms(r => (rooms));
    },
    []
  );

  //
  // new room
  //
  const onAddRoom = useCallback(
    ([roomId, room]) => {
      log.debug("LobbyProvider:", "onAddRoom", roomId);
      setAllRooms((r) => {
        const roomIdx = r.findIndex((room) => room.roomId === roomId);
        if (roomIdx !== -1) {
          log.debug("LobbyProvider:", "onAddRoom", "existing room updated", room.metadata.key);
          const rCopy = r.slice();
          rCopy[roomIdx] = room;
          return rCopy;
        } else {
          log.debug("LobbyProvider:", "onAddRoom", "new room", room.metadata.key);
          log.debug(room.metadata.key, room.metadata.open);
          log.debug(room.metadata);
          return [...r, room];
        }
      });
    },
    []
  );

  //
  // remove room
  //
  const onDeleteRoom = useCallback(
    (roomId) => {
      log.debug("LobbyProvider:", "onDeleteRoom", roomId);
      setAllRooms(r => (r.filter((room) => room.roomId !== roomId)));
    },
    []
  );

  //
  // join lobby
  //
  useEffect(() => {
    async function joinLobby() {
      log.info("LobbyProvider:", "joinLobby");
      try {
        const newLobby = await client.joinOrCreate("lobby", { accessToken: user.token, show_id: show._id });

        newLobby.onMessage("rooms", onRooms);
        newLobby.onMessage("+", onAddRoom);
        newLobby.onMessage("-", onDeleteRoom);

        newLobby.onStateChange((updatedState) => {
          log.debug("LobbyProvider: showState onChange", updatedState.showState);
          setShowState(updatedState.showState);
        });

        newLobby.onLeave((code) => {
          log.info("LobbyProvider:", "onLeave", code);
          newLobby.removeAllListeners();
          newLobby.onStateChange.clear();
          // newLobby.leave();
          setLobby(null);
          setAllRooms([]);
        });

        const rooms = await client.getAvailableRooms();
        log.info("LobbyProvider:", "joinLobby", rooms);

        setLobby(newLobby);
      } catch (err) {
        log.error("LobbyProvider:", "joinLobby", err);
      } finally {
        setLoading(false);
      }
    }

    if (!lobby) {
      joinLobby();
    }
  }, [client, lobby, user, show._id, onRooms, onAddRoom, onDeleteRoom ]);

  //
  // leave lobby
  //
  useEffect(() => {
    function leaveLobby() {
      if (lobby) {
        log.info("LobbyProvider:", "leaveLobby");
        lobby.removeAllListeners();
        lobby.onStateChange.clear();
        lobby.leave();
        setLobby(null);
        setAllRooms([]);
      }
    };

    return () => {
      if (lobby) {
        log.debug("LobbyProvider:", "Unmount");
        leaveLobby();
      }
    };
  }, [lobby]);

  if (loading || showState === "Loading") {
    return <Spinner />;
  }

  return (
    <LobbyContext.Provider value={{
      lobby,
      allRooms: allRooms.filter(r => r.name !== "chat_room"),
      showState
    }} {...props} />
  );
}

const useLobby = () => useContext(LobbyContext);

export { LobbyProvider, useLobby };
